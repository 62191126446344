import React, { useEffect, useState, useCallback } from 'react';
import { Form, Button, Input, Tooltip, Checkbox, Col, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import isEmpty from 'lodash.isempty';
import { DRIVER_STATUS_TAGS } from 'constants/drivers';
import PhoneInput from 'components/Shared/PhoneInput';
import isValidPhoneNumber from 'utils/phoneUtils';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';

import styles from './style.module.scss';

const DriverForm = ({
  currentCorporation,
  formData,
  initialValues = {},
  onCancel,
  onSubmit,
  submitting,
  visible,
}) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const { executeRecaptcha } = useRecaptcha();
  const [form] = Form.useForm();
  const { getFieldValue, setFieldsValue, validateFields } = form;
  const [canEditMobilePhone, setCanEditMobilePhone] = useState(true);

  const isEditMode = !!formData.id;

  const resetForm = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverSave);

    try {
      const values = await validateFields();

      values.InvitationBySms = values.invitationOptions?.includes('InvitationBySms');
      values.InvitationByEmail = values.invitationOptions?.includes('InvitationByEmail');
      delete values?.invitationOptions;

      const recaptchaToken = await executeRecaptcha('submit');

      onSubmit({ ...values, recaptchaToken });
    } catch (error) {}
  };

  const isDriverDeactivated = () => {
    const driverStatus = DRIVER_STATUS_TAGS[formData.status];
    if (!driverStatus) return false;

    return isEditMode && driverStatus.key === 'deactivated';
  };

  const onInputChange = (fieldName, value) => {
    setFieldsValue({ [fieldName]: value });
  };

  const costCenterAllowedCharacterRegex = /[^a-z0-9-_]/gi;

  const onCostCenterChange = event => {
    const value = event.target.value.replace(costCenterAllowedCharacterRegex, '');
    setFieldsValue({ costCenter: value });
  };

  useEffect(() => {
    if (isEmpty(formData)) {
      resetForm();
    } else if (formData.id) {
      setFieldsValue(formData);
      setCanEditMobilePhone(
        DRIVER_STATUS_TAGS[formData.status].key ===
          ('new' || 'inviteSent' || 'inviteFailed' || 'deleted'),
      );
    }
  }, [formData, resetForm, setFieldsValue]);

  useEffect(() => {
    if (!visible) resetForm();
  }, [visible, resetForm]);

  return (
    <div data-testid="driverForm">
      <Form
        form={form}
        layout="vertical"
        requiredMark="optional"
        style={{ paddingBottom: '100px' }}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="corporateClientId" hidden initialValue={initialValues.corporateClientId}>
          <Input />
        </Form.Item>
        <Form.Item
          name="firstName"
          initialValue=""
          validateFirst
          validateTrigger="onBlur"
          label={translateText('corporateAccounts.drivers.firstName')}
          rules={[
            {
              required: true,
              message: translateText('corporateAccounts.admins.form.firstNameRequired'),
            },
            {
              max: 50,
              message: translateText('corporateAccounts.drivers.form.firstNameValidation'),
            },
          ]}
        >
          <Input
            placeholder={isEditMode ? translateText('corporateAccounts.common.empty') : ''}
            disabled={isDriverDeactivated()}
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          initialValue=""
          validateFirst
          validateTrigger="onBlur"
          label={translateText('corporateAccounts.drivers.lastName')}
          rules={[
            {
              required: true,
              message: translateText('corporateAccounts.admins.form.lastNameRequired'),
            },
            {
              max: 50,
              message: translateText('corporateAccounts.drivers.form.lastNameValidation'),
            },
          ]}
        >
          <Input
            placeholder={isEditMode ? translateText('corporateAccounts.common.empty') : ''}
            disabled={isDriverDeactivated()}
          />
        </Form.Item>
        <Form.Item
          name="mobilePhoneNumber"
          validateFirst
          validateTrigger="onBlur"
          label={translateText('corporateAccounts.drivers.mobilePhoneNumber')}
          rules={[
            {
              required: true,
              message: translateText('corporateAccounts.drivers.form.phoneRequired'),
            },
            {
              validator: (rule, value) =>
                value ? isValidPhoneNumber(getFieldValue('mobilePhoneNumber')) : Promise.resolve(),
              message: translateText('corporateAccounts.drivers.form.phoneValidation'),
            },
          ]}
        >
          <PhoneInput
            code={currentCorporation?.country}
            disabled={!canEditMobilePhone}
            fieldValue="mobilePhoneNumber"
            onInputChange={onInputChange}
          />
        </Form.Item>
        <Form.Item
          data-testid="email"
          name="email"
          validateFirst
          validateTrigger="onBlur"
          label={translateText('corporateAccounts.drivers.email')}
          rules={[
            {
              required: true,
              message: translateText('corporateAccounts.drivers.form.emailRequired'),
            },
            {
              type: 'email',
              message: translateText('corporateAccounts.drivers.form.emailValidation'),
            },
          ]}
        >
          <Input disabled={isDriverDeactivated()} />
        </Form.Item>
        <Form.Item
          name="costCenter"
          initialValue=""
          validateFirst
          validateTrigger="onBlur"
          label={
            <Tooltip title={translateText('corporateAccounts.drivers.costCenterInfo')}>
              {translateText('corporateAccounts.drivers.costCenter')}
              <InfoCircleOutlined className="infoCircle" />
            </Tooltip>
          }
          rules={[
            {
              max: 15,
              message: translateText('corporateAccounts.drivers.form.costCenterMaxLength', {
                maxLength: 15,
              }),
            },
          ]}
        >
          <Input
            placeholder={translateText('corporateAccounts.common.empty')}
            disabled={isDriverDeactivated()}
            onChange={onCostCenterChange}
          />
        </Form.Item>

        {!isEditMode && (
          <Form.Item
            name="invitationOptions"
            data-testid="invitationOptions"
            className={styles.menuItem_driverInvitationOptions}
            validateTrigger="onChange"
            label={
              <Tooltip title={translateText('corporateAccounts.drivers.inviteDriverToolTip')}>
                {translateText('corporateAccounts.drivers.form.invitationOptions')}
                <InfoCircleOutlined className="infoCircle" />
              </Tooltip>
            }
            rules={[
              {
                required: true,
                message: '',
              },
              {
                validator: (_, value) => {
                  if (value === undefined || value.length === 0) {
                    return Promise.reject(
                      translateText('corporateAccounts.drivers.form.selectInvitationMethod'),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Checkbox.Group>
              <Col>
                <Row className={styles.menuItem_checkbox}>
                  <Checkbox name="InvitationBySms" value="InvitationBySms">
                    {translateText('corporateAccounts.drivers.form.sms')}
                  </Checkbox>
                </Row>
                <Row className={styles.menuItem_checkbox}>
                  <Checkbox name="InvitationByEmail" value="InvitationByEmail">
                    {translateText('corporateAccounts.drivers.email')}
                  </Checkbox>
                </Row>
              </Col>
            </Checkbox.Group>
          </Form.Item>
        )}
      </Form>
      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button onClick={handleCancel} style={{ marginRight: 8 }} disabled={submitting}>
          {translateText('corporateAccounts.actions.cancel')}
        </Button>
        <Button
          data-testid="submitDriverFormBtn"
          onClick={handleSubmit}
          type="primary"
          loading={submitting}
          disabled={isDriverDeactivated() || submitting}
        >
          {formData.id
            ? translateText('corporateAccounts.drivers.form.update')
            : translateText('corporateAccounts.drivers.form.add')}
        </Button>
      </div>
    </div>
  );
};

export default DriverForm;
