import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from 'utils/hooks/useTranslation';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import Modal from 'components/Shared/Modal';
import DriverImportModalFooter from './DriverImportModalFooter';
import DriverImportModalContent from './DriverImportModalContent';
import DriverImportModalLoading from './DriverImportModalLoading';
import useDriverImportModal from './useDriverImportModal';
import DriverImportModalFailed from './DriverImportModalFailed';

const DriverImportModal = ({ visible, country, onExport, onClose }) => {
  const { translateText } = useTranslation();
  const { executeRecaptcha } = useRecaptcha();
  const {
    loading,
    fileList,
    error,
    isError,
    isSuccess,
    validationErrors,
    onFileUpload,
    onImport,
    onReset,
    invitationMethodEmail,
    invitationMethodSms,
    setInvitationMethodSms,
    setInvitationMethodEmail,
  } = useDriverImportModal();

  const adminRole = useSelector(state => state.user?.adminRole);

  const handleConfirm = async () => {
    const recaptchaToken = await executeRecaptcha('submit');
    onImport(recaptchaToken);
  };

  const handleClose = useCallback(() => {
    onClose();
    onReset();
  }, [onClose, onReset]);

  const isImportDisabled =
    (!adminRole && invitationMethodEmail === false && invitationMethodSms === false) ||
    !fileList.length ||
    isError;

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  return (
    <Modal
      title={translateText('corporateAccounts.drivers.importModal.title')}
      width={500}
      visible={visible}
      centered
      closable
      footer={
        loading ? null : (
          <DriverImportModalFooter
            loading={loading}
            importDisabled={isImportDisabled}
            onClose={handleClose}
            onConfirm={handleConfirm}
            validationErrors={validationErrors}
          />
        )
      }
      onCancel={handleClose}
      destroyOnClose
    >
      {loading ? (
        <DriverImportModalLoading />
      ) : (
        <DriverImportModalContent
          fileList={fileList}
          country={country}
          onExport={onExport}
          onFileUpload={onFileUpload}
          setInvitationMethodEmail={setInvitationMethodEmail}
          setInvitationMethodSms={setInvitationMethodSms}
          invitationMethodEmail={invitationMethodEmail}
          invitationMethodSms={invitationMethodSms}
        />
      )}
      {isError && !loading && <DriverImportModalFailed error={error} />}
    </Modal>
  );
};

export default React.memo(DriverImportModal);
