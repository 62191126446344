import store from 'store';
import { PURGE } from 'redux-persist';
import userActions from '../user/actions';
import actions from './actions';
import { getBrowserDefaultLanguage } from 'utils/browserLanguageCountryUtil.js';
import { getOperatingSystem } from 'utils/operatingSystemUtil.js';

const STORED_SETTINGS = storedSettings => {
  const settings = {};
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`);
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key];
  });
  return settings;
};

export const INITIAL_STATE = {
  isMobileView: false,
  isMobileMenuOpen: false,
  isLightTheme: true,
  isSettingsOpen: false,
  isMenuTop: false,
  isMenuCollapsed: false,
  isBorderless: true,
  isSquaredBorders: false,
  isFixedWidth: false,
  isMenuShadow: true,
  isRecaptchaLoaded: false,
  hasAcceptedPerformanceCookies: false,
  showSignInButton: true,
  showLanguageSelector: true,
  operatingSystem: getOperatingSystem(),
  locale: getBrowserDefaultLanguage(),
};

export default function settingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case PURGE:
    case userActions.LOGOUT:
      return { ...STORED_SETTINGS(INITIAL_STATE) };
    default:
      return state;
  }
}
