import { setUserId, setUserProperties } from '../../utils/amplitudeUtil';
import { setRaygunUserId } from '../../utils/raygunUtil';
import actions from './actions';

const INITIAL_STATE = {
  adminTablePageSize: 100,
  authorized: false,
  activationId: null,
  activationSuccess: false,
  corporateClientId: null,
  corporations: [],
  corporationRole: '',
  currentCorporation: null,
  restrictedCorporateClientProspect: false,
  emailNotVerified: false,
  onboardingProgress: {
    status: '',
    isOnboardingComplete: false,
    progress: {
      isAccountSetupComplete: false,
      isPaymentSetupComplete: false,
      isVehicleSetupComplete: false,
      isDriverSetupComplete: false,
    },
  },
  firstName: '',
  lastName: '',
  id: null, // this property will be renamed to userAccountId in the future
  userId: null,
  adminRole: false,
  isCreatingCorp: false,
  loading: false,
  logout: false,
  role: '',
  creditAccountMigrationConfig: null,
};

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.SET_STATE:
      const userProperties = {
        role: state.corporationRole,
      };

      if (!state.adminRole) {
        userProperties.companyName = state.corporations.map(corp => corp.corporateClientName);
      }

      setUserId(state.id);
      setUserProperties(userProperties);
      setRaygunUserId(state.id);
      return { ...state, ...action.payload };
    case actions.SET_ADMIN_TABLE_PAGE_SIZE:
      return {
        ...state,
        adminTablePageSize: action.payload,
      };
    case actions.UPDATE_CURRENT_CORPORATION: {
      const corporation = state.currentCorporation;
      const updatedFields = action.payload;
      const updatedCorporation = { ...corporation, ...updatedFields };

      const corporations = state.corporations;
      const updatedCorps = corporations.map(corp => {
        if (corp.id === corporation.id) {
          corp = updatedCorporation;
        }
        return corp;
      });

      return {
        ...state,
        currentCorporation: updatedCorporation,
        corporations: updatedCorps,
      };
    }
    case actions.LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
