import React, { useEffect } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import formatPhoneNumber from 'utils/formatPhoneNumber.js';
import useTranslation from 'utils/hooks/useTranslation';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { isValidDriverPassword } from 'utils/passwordUtils';
import useAmplitude from 'utils/hooks/useAmplitude';
import useIsFormValid from 'utils/hooks/useIsFormValid';
import SocialSignIn from './SocialSignIn';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { FORM_FIELD_NAMES } from 'constants/selfRegistration';

import styles from './style.module.scss';

const RegistrationForm = ({
  driverInfo,
  onRegister,
  renderAppleSignInButton,
  enableFacebookSignIn,
  registrationCode,
}) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const { executeRecaptcha } = useRecaptcha();

  const [form] = Form.useForm();
  const { validateFields } = form;

  const { isFormValid, validateForm } = useIsFormValid(form);

  const handleSubmit = async () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.onboardingDriverRegistrationButton);

    try {
      const values = await validateFields();
      const recaptchaToken = await executeRecaptcha('submit');

      onRegister({ password: values.password, recaptchaToken });
    } catch (error) {}
  };

  const validateMessages = {
    types: {
      password: '${value}',
    },
  };

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.onboardingDriverRegistrationForm);
  }, [sendAmplitudeEvent]);

  return (
    <div className={styles.registrationForm} data-testid="registrationForm">
      <Typography.Title level={4} className={styles.registrationFormTitle}>
        <p>{translateText('corporateAccounts.onboarding.registration.formTitle')}</p>
      </Typography.Title>
      <Typography.Paragraph className={styles.registrationDescription}>
        <p>
          {translateText('corporateAccounts.onboarding.registration.formDescription', {
            companyName: driverInfo.corporateClientName,
          })}
        </p>
      </Typography.Paragraph>
      <Form
        form={form}
        layout="vertical"
        validateMessages={validateMessages}
        onFieldsChange={validateForm}
      >
        <Form.Item label={translateText('corporateAccounts.reports.phoneNumber')} size="small">
          <Input
            defaultValue={formatPhoneNumber(driverInfo.mobilePhoneNumber)}
            disabled={true}
            data-testid={FORM_FIELD_NAMES.mobilePhoneNumber}
          />
        </Form.Item>
        <Form.Item
          name={FORM_FIELD_NAMES.password}
          label={translateText('corporateAccounts.common.password')}
          validateFirst
          data-testid={FORM_FIELD_NAMES.password}
          rules={[
            {
              required: true,
              message: 'Please enter a password',
            },
            {
              type: 'password',
              validator: (rule, value) =>
                value ? isValidDriverPassword(value, translateText) : Promise.resolve(),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
      <div className={styles.registrationButton}>
        <Button type="primary" size="large" onClick={() => handleSubmit()} disabled={!isFormValid}>
          {translateText('corporateAccounts.actions.register')}
        </Button>
      </div>
      <SocialSignIn
        renderAppleSignInButton={renderAppleSignInButton}
        enableFacebookSignIn={enableFacebookSignIn}
        registrationCode={registrationCode}
      />
    </div>
  );
};

export default RegistrationForm;
