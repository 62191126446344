import React from 'react';
import useTranslation from 'utils/hooks/useTranslation';
import { Button, Typography } from 'antd';
import { AMPLITUDE_EVENT_TYPES, AMPLITUDE_USER_ACTIONS } from 'constants/amplitude';
import useAmplitude from 'utils/hooks/useAmplitude';

import styles from './style.module.scss';

const CreditAccountRejectedBanner = () => {
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();

  return (
    <div className={styles.rejectedBannerContainer}>
      <section className={styles.rejectedBannerSection}>
        <Typography className={styles.rejectedBannerTitle}>
          {translateText('corporateAccounts.creditAccount.rejectedBanner.title')}
        </Typography>
        <Typography>
          {translateText('corporateAccounts.creditAccount.rejectedBanner.description')}
        </Typography>
        {/* TODO: change this to the correct link in a future ticket */}
        <a
          href="https://google.com"
          target="_blank"
          rel="noreferrer"
          onClick={() =>
            sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.creditAccountPage, {
              userAction: AMPLITUDE_USER_ACTIONS.prepaymentInquiryClicked,
            })
          }
        >
          <Button type="primary">
            {translateText('corporateAccounts.creditAccount.rejectedBanner.cta')}
          </Button>
        </a>
      </section>
    </div>
  );
};

export default CreditAccountRejectedBanner;
