import React from 'react';
import { Form, Checkbox, Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';

import styles from './style.module.scss';

const DriverInvitationModalContent = ({
  handleSmsChange,
  handleEmailChange,
  isBulkInvite,
  form,
}) => {
  const { translateText } = useTranslation();

  return (
    <div data-testid="inviteModalContent">
      <Typography.Text>
        {isBulkInvite
          ? translateText('corporateAccounts.drivers.bulkResendInviteModal.description')
          : translateText('corporateAccounts.drivers.resendInviteModal.description')}
      </Typography.Text>

      <Form
        form={form}
        layout="vertical"
        style={{
          paddingTop: '24px',
        }}
      >
        <Form.Item
          data-testid="invitationOptions"
          className={styles.menuItem_driverInvitationOptions}
          label={
            <Typography.Text>
              {translateText('corporateAccounts.drivers.form.invitationOptions')}
            </Typography.Text>
          }
        >
          <Form.Item name="InvitationBySms" initialValue={false} valuePropName="checked">
            <Checkbox data-testid="invitationSms" onChange={handleSmsChange}>
              <Typography.Text>
                {translateText('corporateAccounts.drivers.form.sms')}
              </Typography.Text>
            </Checkbox>
          </Form.Item>
          <Form.Item name="InvitationByEmail" initialValue={false} valuePropName="checked">
            <Checkbox data-testid="invitationEmail" onChange={handleEmailChange}>
              <Typography.Text>{translateText('corporateAccounts.drivers.email')}</Typography.Text>
            </Checkbox>
          </Form.Item>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DriverInvitationModalContent;
