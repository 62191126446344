import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import useDriversPage from './useDriversPage';
import useOnboardingProgressBar from 'components/Shared/OnboardingProgressBar/useOnboardingProgressBar';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { DRIVER_STATUS } from 'constants/drivers';
import { RESTRICTED_CORPORATE_CLIENT_PROSPECT } from 'constants.js';
import Can from 'config/Can';
import { Helmet } from 'react-helmet';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Modal } from 'antd';
import Authorize from 'components/Providers/Authorize';
import DrawerForm from 'components/Shared/DrawerForm';
import PageWrapper from 'components/Shared/Page/Wrapper';
import PageTitle from 'components/Shared/Page/Title';
import ButtonSearchRow from 'components/Shared/Page/ButtonSearchRow';
import { UploadIcon } from 'components/Shared/Icons';
import { DownloadOutlined } from '@ant-design/icons';
import PageBanners from 'components/Layout/PageBanners';
import withPusher from 'components/Providers/Pusher/withPusher';
import { injectIntl } from 'react-intl';
import DriversTable from './DriversTable';
import DriverForm from './DriverForm';
import DriversEmptyState from './DriversEmptyState';
import DriverImportModal from './DriverImportModal';
import tableColumns from './tableColumns';
import driverHelper from './helper';
import styles from './style.module.scss';
import DriverInvitationModal from './DriverInvitationModal';
import { setHotjarUserAttributes } from 'utils/hotjarUtil';
import RegisterCorporationModal from 'pages/Corporations/RegisterCorporation/index.js';

const Drivers = ({ history }) => {
  const {
    drivers,
    hasDrivers,
    isPageLoading,
    isDriverLoading,
    isAddLoading,
    isEditLoading,
    isExportLoading,
    currentPage,
    statusFilters,
    totalSize,
    selectedTableRows,
    searchQuery,
    onAddDriver,
    onEditDriver,
    onDeleteDriver,
    onInviteDriver,
    onBulkDeleteDrivers,
    onExportDrivers,
    onPageChange,
    onPageSizeChange,
    onSearch,
    onTableChange,
    onRowSelectChange,
  } = useDriversPage();

  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const [formVisibility, setFormVisibility] = useState(false);
  const [showDriverImport, setShowDriverImport] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [inviteDriverIds, setInviteDriverIds] = useState([]);
  const [isBulkInvite, setIsBulkInvite] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const [formData, setFormData] = useState({});
  const [initialValues, setInitialValues] = useState();
  const clearFormData = () => setFormData({});
  const { id: userId, corporateClientId, corporationRole, currentCorporation } = useSelector(
    state => state.user,
  );
  const { onGetOnboardingProgress } = useOnboardingProgressBar();

  const handleAddItem = (amplitudeEvent = AMPLITUDE_EVENT_TYPES.driverAdd) => {
    sendAmplitudeEvent(amplitudeEvent);
    driverHelper.add({
      corporateClientId,
      setInitialValues,
      setFormTitle,
      setFormVisibility,
      translateText,
    });
  };

  const handleEditItem = async id => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverEdit);
    driverHelper.edit({
      data: drivers,
      id,
      setFormData,
      setFormTitle,
      setFormVisibility,
      translateText,
    });
  };

  const handleInviteDriver = driverId => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverInvitation);
    setInviteDriverIds([driverId]);
    setIsBulkInvite(false);
    setShowInvite(true);
  };

  const handleBulkInvite = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverBulkInvitation);
    setInviteDriverIds(selectedTableRows.map(driver => driver.id));
    setIsBulkInvite(true);
    setShowInvite(true);
  };

  const handleSubmit = values => {
    if (values.id) {
      onEditDriver(values);
      return;
    }

    clearFormData();
    onAddDriver(values);
  };

  const toggleDrawerVisibility = visible => {
    if (visible === false) clearFormData();
    setFormVisibility(visible);
  };

  const handleOnImport = (amplitudeEvent = AMPLITUDE_EVENT_TYPES.driverInitialImport) => {
    sendAmplitudeEvent(amplitudeEvent);
    setShowDriverImport(true);
  };

  const handleExport = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverExport);
    Modal.confirm({
      title: translateText('corporateAccounts.drivers.export.modal.title'),
      content: translateText('corporateAccounts.drivers.export.modal.description'),
      icon: null,
      okText: translateText('corporateAccounts.actions.exportData'),
      cancelText: translateText('corporateAccounts.actions.cancel'),
      onOk() {
        onExportDrivers(searchQuery, statusFilters);
      },
    });
  };

  const handleDeleteDriver = driverId => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverDelete);
    driverHelper.deleteSingleDriver({
      onConfirmDelete: () => {
        onDeleteDriver(driverId);
      },
      translateText,
    });
  };

  const handleBulkDelete = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverBulkDelete);
    const rowsToDelete = selectedTableRows.map(row => row.id);
    driverHelper.bulkDelete({
      onConfirmDelete: () => {
        onBulkDeleteDrivers(rowsToDelete);
        onRowSelectChange(null, []);
      },
      translateText,
    });
  };

  useEffect(() => {
    setHotjarUserAttributes({
      userId,
      language: currentCorporation?.preferredLanguage,
      country: currentCorporation?.country,
      role: corporationRole,
      isTestCorporation: currentCorporation?.isTestCorporation ?? false,
    });
  }, [userId, corporationRole, currentCorporation]);

  useEffect(() => {
    onGetOnboardingProgress();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <RegisterCorporationModal />
      <Authorize>
        <Helmet title="Drivers" />
        <PageBanners />
        <DrawerForm
          title={formTitle}
          width={320}
          visible={formVisibility}
          onClose={() => toggleDrawerVisibility(false)}
        >
          <DriverForm
            currentCorporation={currentCorporation}
            formData={formData}
            initialValues={initialValues}
            onCancel={() => toggleDrawerVisibility(false)}
            onSubmit={handleSubmit}
            submitting={isAddLoading || isEditLoading}
            visible={formVisibility}
          />
        </DrawerForm>

        <PageWrapper loading={isPageLoading} history={history}>
          <PageTitle
            title={translateText('corporateAccounts.drivers.main.title')}
            numRecords={totalSize}
          />

          <DriversEmptyState
            visible={
              (!isPageLoading && !hasDrivers) ||
              corporateClientId === RESTRICTED_CORPORATE_CLIENT_PROSPECT
            }
            handleAddDriver={() =>
              handleAddItem(AMPLITUDE_EVENT_TYPES.driversEmptyStateAddButtonClicked)
            }
            onImportClick={() =>
              handleOnImport(AMPLITUDE_EVENT_TYPES.driversEmptyStateImportButtonClicked)
            }
          />

          <ButtonSearchRow
            visible={!isPageLoading && hasDrivers}
            searchOptions={{
              handleSearch: onSearch,
              searchQuery,
              placeholder: translateText('corporateAccounts.drivers.search.placeholder'),
            }}
            primaryBtn={
              <Can I="create" a="Driver" passThrough>
                {can => (
                  <Button
                    data-testid="addDriverBtn"
                    type="primary"
                    size="large"
                    onClick={() => handleAddItem()}
                    disabled={!can}
                  >
                    <PlusOutlined />
                    {translateText('corporateAccounts.drivers.actions.addParker')}
                  </Button>
                )}
              </Can>
            }
            secondaryBtn={
              <Space size={10}>
                <Can I="export" a="Driver" passThrough>
                  {can => (
                    <Button
                      className={styles.buttonIcon}
                      data-testid="exportDriversBtn"
                      type="secondary"
                      size="large"
                      icon={
                        <span>
                          <DownloadOutlined />
                        </span>
                      }
                      onClick={handleExport}
                      disabled={!can || !totalSize}
                      loading={isExportLoading}
                    >
                      {translateText('corporateAccounts.actions.export')}
                    </Button>
                  )}
                </Can>
                <Can I="bulkDriverUpload" a="RestrictedDriverUpload" passThrough>
                  {can =>
                    can && (
                      <Button
                        className={styles.buttonIcon}
                        type="secondary"
                        size="large"
                        icon={
                          <span>
                            <Icon component={UploadIcon} />
                          </span>
                        }
                        onClick={() => handleOnImport()}
                        disabled={!can}
                      >
                        {translateText('corporateAccounts.common.upload')}
                      </Button>
                    )
                  }
                </Can>
              </Space>
            }
            bulkActionBtns={[
              <Can key="batchDeleteDriver" I="destroy" a="Driver" passThrough>
                {can => (
                  <Button
                    data-testid="batchDeleteBtn"
                    hidden={!can || selectedTableRows.length === 0}
                    type="secondary"
                    size="middle"
                    onClick={handleBulkDelete}
                  >
                    {translateText('corporateAccounts.actions.delete')}
                  </Button>
                )}
              </Can>,
              <Can key="activateDriver" I="activate" a="Driver" passThrough>
                {can => (
                  <Button
                    data-testid="batchInviteBtn"
                    onClick={handleBulkInvite}
                    hidden={
                      !can ||
                      selectedTableRows.length === 0 ||
                      selectedTableRows.every(driver => driver.status === DRIVER_STATUS.ACTIVE)
                    }
                  >
                    {translateText('corporateAccounts.drivers.actions.reinvite')}
                  </Button>
                )}
              </Can>,
            ]}
          />
          <DriversTable
            columns={tableColumns({
              translateText,
              handleEditItem,
              handleInviteDriver,
              handleDeleteDriver,
              statusFilters,
            })}
            currentPage={currentPage}
            dataSource={drivers}
            loading={isDriverLoading}
            selectedTableRows={selectedTableRows}
            totalCount={totalSize}
            visible={hasDrivers}
            onRowSelectChange={onRowSelectChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onTableChange={onTableChange}
          />
          {showDriverImport && (
            <DriverImportModal
              visible={showDriverImport}
              onClose={() => setShowDriverImport(false)}
              onExport={onExportDrivers}
            />
          )}

          {showInvite && (
            <DriverInvitationModal
              visible={showInvite}
              onClose={() => setShowInvite(false)}
              onConfirmInvite={inviteData => onInviteDriver(inviteData)}
              driverIds={inviteDriverIds}
              isBulkInvite={isBulkInvite}
            />
          )}
        </PageWrapper>
      </Authorize>
    </>
  );
};

export default withPusher(withRouter(injectIntl(Drivers)));
