import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import {
  DATADOG_CLIENT_TOKEN,
  DATADOG_RUM_APP_ID,
  DATADOG_RUM_CLIENT_TOKEN,
  PORTAL_ENV,
} from 'constants.js';

const duplicateErrorLogsToIgnore = [
  'console error: Error: Request failed with status code 400',
  'console error: Error: Request failed with status code 409',
  'console error: Error: Network Error',
];

export const initializeDatadog = () => {
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    isCollectingError: true,
    sampleRate: 100,
    forwardErrorsToLogs: true,
    beforeSend: log => {
      if (duplicateErrorLogsToIgnore.includes(log.message)) {
        return false;
      }

      //mask FB sign in access_token
      if (log?.view?.url) {
        log.view.url = log.view.url
          .replace(/access_token=[^&]*/, 'access_token=REDACTED')
          .replace(/code=[^&]*/, 'code=REDACTED')
          .replace(/long_lived_token=[^&]*/, 'long_lived_token=REDACTED');
      }

      if (log?.view?.url_details?.hash) {
        delete log.view.url_details.hash;
      }

      if (log?.user) {
        log.user.access_token = 'REDACTED';
        log.user.id_token = 'REDACTED';
      }
    },
  });

  datadogLogs.setGlobalContextProperty('service', 'corporateAccountsWebAdminPortal');
  datadogLogs.setGlobalContextProperty('host', PORTAL_ENV);
  datadogLogs.setGlobalContextProperty('env', PORTAL_ENV);

  datadogRum.init({
    applicationId: DATADOG_RUM_APP_ID,
    clientToken: DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'corporateaccountswebadminportal',
    env: PORTAL_ENV,
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask',
  });

  datadogRum.startSessionReplayRecording();
};

export const useEnrichDatadogLogs = () => {
  const currentUser = useSelector(state => state.user);
  const { id, corporateClientId, corporationRole, currentCorporation } = currentUser;

  useEffect(() => {
    datadogLogs.setGlobalContextProperty('corporateClientId', corporateClientId);
    datadogLogs.setGlobalContextProperty('userAccountId', id);
    datadogLogs.setGlobalContextProperty('userRole', corporationRole);
  }, [currentUser, id, corporateClientId, corporationRole, currentCorporation]);
};
