import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { initializeDatadog } from 'utils/datadogUtil';
import { deinitializeAmplitude, initializeAmplitude } from 'utils/amplitudeUtil';
import { deinitializeHotjar, initializeHotjar } from 'utils/hotjarUtil';
import { initializePusher } from 'components/Providers/Pusher/withPusher/pusher';

const useInitializeTrackingServices = () => {
  const authorized = useSelector(state => state.user?.authorized);
  const { hasAcceptedPerformanceCookies } = useSelector(state => state.settings);

  useEffect(() => {
    if (authorized) {
      initializePusher();
    }
  }, [authorized]);

  useEffect(() => {
    if (hasAcceptedPerformanceCookies) {
      initializeDatadog();
      initializeAmplitude();
      initializeHotjar();
    } else {
      deinitializeAmplitude();
      deinitializeHotjar();
    }
  }, [hasAcceptedPerformanceCookies]);
};

export default useInitializeTrackingServices;
