/* eslint-disable import/prefer-default-export */
export const AMPLITUDE_EVENT_TYPES = {
  zendesk: 'portal_zendeskHelp_button_clicked',
  logout: 'portal_logout_menuOption_clicked',
  switchAccounts: 'portal_switchAccounts_menuOption_clicked',
  subscriptionPage: 'subscription_page_viewed',
  accountCreate: 'account_create_button_clicked',
  accountEnter: 'account_enter_button_clicked',
  accountDelete: 'account_delete_button_clicked',
  accountDeactivate: 'account_deactivate_button_clicked',
  accountReactivate: 'account_reactivate_button_clicked',
  reportPage: 'report_page_viewed',
  loadTransactions: 'report_transactions_load_button_clicked',
  exportReport: 'report_export_button_clicked',
  monthlyTab: 'report_monthly_tab_clicked',
  generateReport: 'report_generateReport_button_clicked',
  downloadReport: 'report_monthly_download_button_clicked',
  sessionPage: 'parking_sessions_page_viewed',
  paymentSave: 'payment_save_button_clicked',
  vehiclePage: 'vehicle_page_viewed',
  vehicleSave: 'vehicle_save_button_clicked',
  vehicleBulkDelete: 'vehicle_bulkDelete_button_clicked',
  vehicleFinalImport: 'vehicle_import_data_button_clicked',
  vehicleExport: 'vehicle_export_button_clicked',
  driverPage: 'driver_page_viewed',
  driverSave: 'driver_save_button_clicked',
  driverAddSucessful: 'driver_add_successfully_completed',
  driverAddFailed: 'driver_add_failed_to_complete',
  driverAddErrorProfileAlreadyExists: 'driver_add_failed_profile_already_exists',
  driverEditSuccessful: 'driver_edit_successfully_completed',
  driverEditFailed: 'driver_edit_failed_to_complete',
  driverDeleteSuccessful: 'driver_delete_successfully_completed',
  driverDeleteFailed: 'driver_delete_failed_to_complete',
  driverBulkDeleteSuccessful: 'driver_bulk_delete_successfully_completed',
  driverBulkDeleteFailed: 'driver_bulk_delete_failed_to_complete',
  driverInvitationSuccessful: 'driver_invitation_successfully_completed',
  driverBulkInvitationSuccessful: 'driver_bulk_invitation_successfully_completed',
  driverInvitationFailed: 'driver_invitation_failed',
  driverBulkInvitationFailed: 'driver_bulk_invitation_failed',
  driverBulkInvitation: 'driver_bulk_invitation_button_clicked',
  driverBulkDelete: 'driver_bulkDelete_button_clicked',
  driverBulkImportSuccessful: 'driver_bulk_import_successfully_completed',
  driverBulkImportFailed: 'driver_bulk_import_failed',
  driverBulkImportCSVFileError: 'driver_bulk_import_CSV_file_error',
  driverFinalImport: 'driver_import_data_button_clicked',
  driverExport: 'driver_export_button_clicked',
  userPage: 'user_page_viewed',
  paymentPage: 'payment_page_viewed',
  paymentDelete: 'payment_delete_button_clicked',
  paymentEdit: 'payment_edit_button_clicked',
  paymentAdd: 'payment_add_button_clicked',
  vehicleAdd: 'vehicle_add_button_clicked',
  vehicleEdit: 'vehicle_edit_button_clicked',
  driverAdd: 'driver_add_button_clicked',
  driverEdit: 'driver_edit_button_clicked',
  support: 'portal_support_button_clicked',
  helpCentre: 'portal_helpCenter_button_clicked',
  addNewAccount: 'account_add_button_clicked',
  accountEdit: 'account_edit_button_clicked',
  accountEditSave: 'account_editSave_button_clicked',
  vehicleSearch: 'vehicle_search_button_clicked',
  vehicleDelete: 'vehicle_delete_button_clicked',
  vehicleInitialImport: 'vehicle_import_button_clicked',
  driverSearch: 'driver_search_button_clicked',
  driverDelete: 'driver_delete_button_clicked',
  driverInvitation: 'driver_invitation_button_clicked',
  driverInitialImport: 'driver_import_button_clicked',
  userAdd: 'user_addUser_button_clicked',
  userSave: 'user_save_button_clicked',
  userInvite: 'user_invite_button_clicked',
  userEdit: 'user_edit_button_clicked',
  userDelete: 'user_delete_button_clicked',
  transactionsError: 'report_transactions_error_button_clicked',
  languageSelected: 'portal_language_dropdown_selected',
  monthlyStatementsPageView: 'statements_page_viewed',
  monthlyStatementDownload: 'statements_download_button_clicked',
  OpenApp: 'open_application_button_clicked',
  onboardingInviteCodeNotValid: 'driver_onboarding_invitation_code_not_valid',
  onboardingInviteCodeNotFound: 'driver_onboarding_invitation_code_not_found',
  onboardingRegistrationRequired: 'driver_onboarding_registration_required',
  onboardingInvitationExpired: 'driver_onboarding_invitation_expired',
  onboardingActivationFailed: 'driver_onboarding_activation_failed',
  onboardingDriverNotFound: 'driver_onboarding_driver_not_found',
  onboardingCorporationNotFound: 'driver_onboarding_corporation_not_found',
  onboardingInternalError: 'driver_onboarding_internal_error',
  onboardingDriverAlreadyActive: 'driver_onboarding_driver_already_activated',
  onboardingDriverSuccessful: 'driver_onboarding_successfully_completed',
  onboardingDriverRegistrationButton: 'driver_registration_button_clicked',
  onboardingDriverRegistrationForm: 'driver_registration_page_viewed',
  onboardingDriverAppleSignInButton: 'driver_registration_apple_sign_in_button_clicked',
  onboardingDriverFacebookSignInButton: 'driver_registration_facebook_sign_in_button_clicked',
  selfRegistrationBusinessNameFieldClicked: 'self_registration_business_name_field_clicked',
  selfRegistrationDriverCountFieldClicked: 'self_registration_driver_count_field_clicked',
  selfRegistrationCountryFieldClicked: 'self_registration_country_field_clicked',
  selfRegistrationFirstNameFieldClicked: 'self_registration_first_name_field_clicked',
  selfRegistrationLastNameFieldClicked: 'self_registration_last_name_field_clicked',
  selfRegistrationPhoneNumberFieldClicked: 'self_registration_phone_number_field_clicked',
  selfRegistrationEmailFieldClicked: 'self_registration_email_field_clicked',
  selfRegistrationPasswordFieldClicked: 'self_registration_password_field_clicked',
  selfRegistrationTermsAndConditionsCheckbox:
    'self_registration_terms_and_conditions_checkbox_clicked',
  selfRegistrationTermsAndConditionsSubscriptionLink:
    'self_registration_subscription_agreement_link_clicked',
  selfRegistrationTermsAndConditionsPrivacyPolicyLink:
    'self_registration_privacy_policy_link_clicked',
  selfRegistrationContactSalesLink: 'self_registration_contact_sales_link_clicked',
  selfRegistrationSignInButton: 'self_registration_sign_in_button_clicked',
  selfRegistrationUserCreationSuccessful: 'self_registration_user_successfully_created',
  selfRegistrationUserCreationFailed: 'self_registration_user_registration_failed_to_complete',
  selfRegistrationUserRegistrationForm: 'self_registration_page_viewed',
  selfRegistrationRegistrationButton: 'self_registration_button_clicked',
  selfRegistrationUserVerificationSuccessful: 'self_registration_user_successfully_verified',
  selfRegistrationUserVerificationFailed: 'self_registration_user_verification_failed_to_complete',
  selfRegistrationUserVerificationCodeExpired: 'self_registration_user_verification_code_expired',
  selfRegistrationUserVerificationCodeNotFound:
    'self_registration_user_verification_code_not_found',
  selfRegistrationUserVerificationPageViewed: 'self_registration_user_verification_page_viewed',
  selfRegistrationUserVerificationContactSupport:
    'self_registration_user_verification_contact_support_link_clicked',
  selfRegistrationResendVerificationEmail: 'self_registration_resend_verification_email',
  selfRegistrationResendVerificationEmailFailed:
    'self_registration_resend_verification_email_failed',
  selfRegistrationCorporateClientRegistrationPageViewed:
    'self_registration_corporate_client_registration_page_viewed',
  selfRegistrationCorporateClientRegistrationCompanyNameFieldClicked:
    'self_registration_corporate_client_registration_company_name_field_clicked',
  selfRegistrationCorporateClientRegistrationDriverCountFieldClicked:
    'self_registration_corporate_client_registration_driver_count_field_clicked',
  selfRegistrationCorporateClientRegistrationCompanyIndustryFieldClicked:
    'self_registration_corporate_client_registration_company_industry_field_clicked',
  selfRegistrationCorporateClientRegistrationStreetAddressFieldClicked:
    'self_registration_corporate_client_registration_street_address_field_clicked',
  selfRegistrationCorporateClientRegistrationOfficeNumFieldClicked:
    'self_registration_corporate_client_registration_office_num_field_clicked',
  selfRegistrationCorporateClientRegistrationCountryFieldClicked:
    'self_registration_corporate_client_registration_country_field_clicked',
  selfRegistrationCorporateClientRegistrationRegionFieldClicked:
    'self_registration_corporate_client_registration_region_field_clicked',
  selfRegistrationCorporateClientRegistrationCityFieldClicked:
    'self_registration_corporate_client_registration_city_field_clicked',
  selfRegistrationCorporateClientRegistrationPostalFieldClicked:
    'self_registration_corporate_client_registration_postal_field_clicked',
  selfRegistrationCorporateClientRegistrationTaxFieldClicked:
    'self_registration_corporate_client_registration_tax_field_clicked',
  selfRegistrationCorporateClientRegistrationSuccessful:
    'self_registration_corporate_client_registration_successful',
  selfRegistrationCorporateClientRegistrationSave:
    'self_registration_corporate_client_save_button_clicked',
  selfRegistrationCreditAccountClientRegistrationSave:
    'self_registration_credit_account_client_save_button_clicked',
  selfRegistrationCorporateClientRegistrationFailed:
    'self_registration_corporate_client_registration_failed',
  selfRegistrationCorporateClientActivationSuccessful:
    'self_registration_corporate_client_activation_successful',
  selfRegistrationCorporateClientActivationFailed:
    'self_registration_corporate_client_activation_failed',
  switchToDesktopModalViewed: 'switch_to_desktop_modal_viewed',
  switchToDesktopModalLogOutButtonClicked: 'switch_to_desktop_modal_log_out_button_clicked',
  paymentEmptyStateAddButtonClicked: 'payment_empty_state_add_button_clicked',
  driversEmptyStateAddButtonClicked: 'drivers_empty_state_add_button_clicked',
  driversEmptyStateImportButtonClicked: 'drivers_empty_state_import_button_clicked',
  vehiclesEmptyStateAddButtonClicked: 'vehicles_empty_state_add_button_clicked',
  vehiclesEmptyStateImportButtonClicked: 'vehicles_empty_state_import_button_clicked',
  billingSaveButton: 'billing_save_button_clicked',
  billingSubscriptionTab: 'billing_subscription_tab_clicked',
  billingAddressTab: 'billing_address_tab_clicked',
  subscriptionDeactivationReasonDifferentApp: 'Account deactivation reason: different parking app',
  subscriptiontDeactivationReasonTooExpensive: 'Account deactivation reason: Too expensive',
  subscriptionAccountDeactivationReasonMissingFeatures:
    'Account deactivation reason: Missing features',
  subscriptionAccountDeactivationReasonUnexpectedProduct:
    'Account deactivation reason: Unexpected product',
  billingAccountDeactivationReasonOther: 'Account deactivation reason: Other',
  billingPage: 'billing_page_viewed',
  selfRegistrationRegisterCorporationButton: 'register_corporation_button_clicked',
  selfRegistrationFaqLink: 'welcomModal_faq_link_clicked',
  selfRegistrationWelcomeModalCloseButton: 'welcome_modal_close_button_clicked',
  subscriptionContactSalesBtn: 'edit_plan_contact_sales_button_clicked',
  auditLogsPage: 'audit_logs_page_viewed',
  howToAddPaymentMethodVideoOpened: 'payment_how_to_video_opened',
  howToAddVehicleVideoOpened: 'vehicle_how_to_video_opened',
  howToAddDriverVideoOpened: 'driver_how_to_video_opened',
  selfRegistrationOnboardingPaymentsLink: 'self_registration_onboarding_payments_link_clicked',
  selfRegistrationOnboardingDriversLink: 'self_registration_onboarding_drivers_link_clicked',
  selfRegistrationOnboardingVehiclesLink: 'self_registration_onboarding_vehicles_link_clicked',
  driverAddLimitExceededError: 'driver_add_failed_limit_exceeded',
  vehicleAddLimitExceededError: 'vehicle_add_failed_limit_exceeded',
  restrictedDriverImportLimitExceededError:
    'restricted_corporate_client_driver_import_failed_limit_exceeded',
  driverImportLimitExceededError: 'driver_import_failed_limit_exceeded',
  vehicleUploadLimitExceededError: 'vehicle_upload_failed_limit_exceeded',
  accountActivationFeedbackEasy: 'account_activation_feedback_easy_clicked',
  accountActivationFeedbackExpected: 'account_activation_feedback_expected_clicked',
  accountActivationFeedbackDifficult: 'account_activation_feedback_difficult_clicked',
  creditAccountPage: 'credit_account',
  subscriptionTab: 'subscription',
  invoicesAndStatementsPage: 'invoices_and_statements',
  creditAccountsInfoModalViewed: 'migration_pop_up_viewed',
  smsReminderEnrollmentModal: 'sms_reminder_entrollment_modal',
};

export const AMPLITUDE_USER_ACTIONS = {
  pageViewed: 'Page viewed',
  downloadClicked: 'Download clicked',
  closeClicked: 'Close clicked',
  setUpClicked: 'Set up clicked',
  faqClicked: 'FAQ clicked',
  prepaymentInquiryClicked: 'Inquire about pre-payment clicked',
  deactivate: 'Deactivate button clicked',
};
