import { AbilityBuilder } from '@casl/ability';
import find from 'lodash.find';
import {
  FLEET_MANAGER,
  PBP_CS_TIER_2,
  PBP_CUSTOMER_SUCCESS,
  PBP_SUPER_ADMIN,
  PRIMARY_FLEET_MANAGER,
  REPORTING,
  SERVICE_ADMIN,
  RESTRICTED_CORPORATE_CLIENT_PROSPECT,
} from 'constants.js';
import actions from './actions';

// Detect role by corporation; users may have many corporations with different roles
export const filterRoleByCorp = (role, corporations, corporateClientId, isPbpStaffRole) => {
  if (isPbpStaffRole) return splitRole(role, isPbpStaffRole);
  if (corporateClientId === RESTRICTED_CORPORATE_CLIENT_PROSPECT) return PRIMARY_FLEET_MANAGER;
  if (role.length === 1) return splitRole(role);

  let corporationRole;
  const corporation = find(corporations, { id: corporateClientId });

  if (corporation) {
    const { id } = corporation;
    const corporationMatch = find(role, r => r.split(':')[2] === id);
    corporationRole = splitRole(corporationMatch);
  }
  return corporationRole;
};

export const splitRole = (permission, isPbpStaffRole = false) => {
  let role;
  if (isPbpStaffRole) return permission;

  if (permission) {
    const permissionsIndex = 3;
    role = permission.toString().split(':')[permissionsIndex];
  }
  return role;
};

export const defineRulesFor = (role, corporations, corporateClientId, isPbpStaffRole) => {
  const { can, cannot, rules } = AbilityBuilder.extract();
  const corporationRole = filterRoleByCorp(role, corporations, corporateClientId, isPbpStaffRole);
  const corporation = find(corporations, { id: corporateClientId });

  const disableBulkDriverUploadForSelfRegisteredCorporations = role => {
    corporation?.registrationType === 'SelfRegistered'
      ? cannot(['bulkDriverUpload'], 'RestrictedDriverUpload')
      : role === REPORTING
      ? cannot(['bulkDriverUpload'], 'RestrictedDriverUpload')
      : can(['bulkDriverUpload'], 'RestrictedDriverUpload');
  };

  if (corporationRole === PBP_SUPER_ADMIN) {
    can(actions.pbpSuperAdmin.all, 'all');

    // Vehicle Form
    can(actions.pbpSuperAdmin.vehicleForm, 'VehicleForm');
  }

  if (corporationRole === PRIMARY_FLEET_MANAGER) {
    can(actions.primaryFleetManager.all, 'all');
    // Vehicle
    can(actions.primaryFleetManager.vehicle, 'Vehicle');
    // Driver
    can(actions.primaryFleetManager.driver.can, 'Driver');
    cannot(actions.primaryFleetManager.driver.cannot, 'Driver');
    can(actions.primaryFleetManager.restrictedDriver, 'RestrictedDriver');
    disableBulkDriverUploadForSelfRegisteredCorporations();
    // VehicleForm
    cannot(actions.primaryFleetManager.vehicleForm, 'VehicleForm');
    // Billing
    can(actions.primaryFleetManager.billing.can, 'Billing');
  }

  if (corporationRole === FLEET_MANAGER) {
    can(actions.fleetManager.all, 'all');
    cannot('view', 'primary_fleet_manager');
    // Vehicle
    can(actions.fleetManager.vehicle, 'Vehicle');
    // Driver
    can(actions.fleetManager.driver.can, 'Driver');
    can(actions.fleetManager.restrictedDriver, 'RestrictedDriver');
    cannot(actions.fleetManager.driver.cannot, 'Driver');
    disableBulkDriverUploadForSelfRegisteredCorporations();
    // VehicleForm
    cannot(actions.fleetManager.vehicleForm, 'VehicleForm');
    // PaymentsSpa
    can(actions.fleetManager.paymentSpa, 'PaymentSpa');
    // Billing
    can(actions.fleetManager.billing.can, 'Billing');
  }

  if (corporationRole === SERVICE_ADMIN) {
    can(actions.serviceAdmin.adminUsers, 'AdminUser');
    // Vehicle
    can(actions.serviceAdmin.vehicle, 'Vehicle');
    // Driver
    can(actions.serviceAdmin.driver.can, 'Driver');
    can(actions.serviceAdmin.restrictedDriver, 'RestrictedDriver');
    cannot(actions.serviceAdmin.driver.cannot, 'Driver');
    disableBulkDriverUploadForSelfRegisteredCorporations();
    // VehicleForm
    cannot(actions.serviceAdmin.vehicleForm, 'VehicleForm');
    // PaymentsSpa
    cannot(actions.serviceAdmin.paymentSpa.cannot, 'PaymentSpa');
    // Billing
    cannot(actions.serviceAdmin.billing.cannot, 'Billing');
  }

  if (corporationRole === REPORTING) {
    can(actions.reporting.adminUsers, 'AdminUser');

    cannot(actions.reporting.driver.cannot, 'Driver');
    cannot(actions.reporting.vehicleForm, 'VehicleForm');
    cannot(actions.reporting.vehicle.cannot, 'Vehicle');
    cannot(actions.reporting.paymentSpa.cannot, 'PaymentSpa');
    cannot(actions.reporting.billing.cannot, 'Billing');
    disableBulkDriverUploadForSelfRegisteredCorporations(REPORTING);
  }

  if (corporationRole === PBP_CUSTOMER_SUCCESS) {
    can(actions.pbpCustomerSuccess.all, 'all');
    cannot(actions.pbpCustomerSuccess.corporation.cannot, 'Corporation');
    cannot(actions.pbpCustomerSuccess.driver.cannot, 'Driver');
    // Vehicle Form
    can(actions.pbpCustomerSuccess.vehicleForm, 'VehicleForm');
    cannot(actions.pbpCustomerSuccess.paymentSpa.cannot, 'PaymentSpa');
  }

  if (corporationRole === PBP_CS_TIER_2) {
    can(actions.pbpCustomerSuccessTierTwo.all, 'all');
    cannot(actions.pbpCustomerSuccessTierTwo.corporation.cannot, 'Corporation');
    cannot(actions.pbpCustomerSuccessTierTwo.driver.cannot, 'Driver');
    // Vehicle Form
    can(actions.pbpCustomerSuccessTierTwo.vehicleForm, 'VehicleForm');
    cannot(actions.pbpCustomerSuccessTierTwo.paymentSpa.cannot, 'PaymentSpa');
  }

  return rules;
};
