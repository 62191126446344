import { Button, Typography } from 'antd';

import styles from './style.module.scss';

const ResendVerificationComponent = ({ handleResendEmail, translateText }) => (
  <>
    <Typography.Title level={2} className={styles.resendVerificationTitle}>
      {translateText('corporateAccounts.selfRegistration.verification.resendVerification')}
    </Typography.Title>
    <div className={styles.resendEmailButton}>
      <Button type="primary" onClick={() => handleResendEmail()} data-testid={'resendEmailButton'}>
        <p>{translateText('corporateAccounts.selfRegistration.resendEmail.button')}</p>
      </Button>
    </div>
  </>
);

export default ResendVerificationComponent;
