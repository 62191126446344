import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'redux/paymentsSpa/actions';
import { Helmet } from 'react-helmet';
import { Alert, Button, Table, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreditCardImage from 'components/Shared/CardUtils/CreditCardImage';
import Authorize from 'components/Providers/Authorize';
import utilityModal from 'components/Shared/Modal/info';
import PageWrapper from 'components/Shared/Page/Wrapper';
import PageTitle from 'components/Shared/Page/Title';
import PageTable from 'components/Shared/Page/Table';
import ButtonSearchRow from 'components/Shared/Page/ButtonSearchRow';
import withPusher from 'components/Providers/Pusher/withPusher';
import PageBanners from 'components/Layout/PageBanners';
import useOnboardingProgressBar from 'components/Shared/OnboardingProgressBar/useOnboardingProgressBar';
import infographStyles from 'components/Layout/Infograph/style.module.scss';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { getMaximumPaymentAccounts } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { PAS_FAILURE_ERRORS } from 'constants/paymentsSpa';
import { CORPORATE_CLIENT_RESTRICTION_TYPES } from 'constants/corporateClients';
import PaymentsEmptyState from './PaymentsEmptyState';
import ReplacePaymentsBanner from './ReplacePaymentsBanner';
import Can from '../../config/Can';
import tableColumns from './tableColumns';
import paymentHelper from './helper';
import { useFeatureFlag } from 'components/Providers/FeatureFlag';

import styles from './style.module.scss';

const PaymentsSpa = ({ history }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const dispatch = useDispatch();
  const { data, loading, submitting } = useSelector(state => state.paymentsSpa);
  const { currentCorporation, creditAccountMigrationConfig } = useSelector(state => state.user);
  const { translateText } = useTranslation();
  const { onGetOnboardingProgress } = useOnboardingProgressBar();
  const { showFeature } = useFeatureFlag();

  const shouldRestrictNumberOfPaymentMethods =
    currentCorporation?.restrictionType?.toLowerCase() ===
      CORPORATE_CLIENT_RESTRICTION_TYPES.RESTRICTED && data.length >= getMaximumPaymentAccounts();

  const addSpa = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.paymentAdd);
    paymentHelper.handleNavigateToAddPayment(history);
  };

  const addPaymentButton = () => {
    return (
      <Can I="create" a="PaymentSpa" passThrough>
        {can => (
          <Button
            type="primary"
            data-testid="addPaymentBtn"
            disabled={!can}
            size="large"
            onClick={addSpa}
          >
            <PlusOutlined />
            {translateText('corporateAccounts.payments.actions.addPayment')}
          </Button>
        )}
      </Can>
    );
  };

  const editSpa = formData => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.paymentEdit);
    paymentHelper.handleNavigateToEditPayment(history);
    dispatch({ type: actions.SET_FORM_DATA, payload: formData });
  };

  const deleteSpa = formData => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.paymentDelete);
    const formattedCard = `**** **** **** ${
      formData.cardNumber ? formData.cardNumber.substring(formData.cardNumber.length - 4) : '****'
    }`;
    utilityModal.warningModal({
      title: translateText('corporateAccounts.actions.delete'),
      content: (
        <>
          {translateText('corporateAccounts.payments.modal.delete.areYouSure')}
          <span className={styles.creditCardContainer}>
            <CreditCardImage paymentCardType={formData.paymentCardType} /> {formattedCard}
          </span>
          {data.length === 1 && translateText('corporateAccounts.payments.modal.delete.lastCard')}
        </>
      ),
      okText: translateText('corporateAccounts.actions.delete'),
      okButtonProps: { type: 'danger', danger: true },
      func: () => dispatch({ type: actions.DELETE, payload: formData }),
    });
  };

  const showNoPaymentUI = () => data.length === 0;

  useEffect(() => {
    if (!submitting) dispatch({ type: actions.GET });
  }, [submitting, dispatch]);

  useEffect(() => {
    onGetOnboardingProgress();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({ type: actions.SET_FORM_DATA, payload: null });
  }, [dispatch]);

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.paymentPage);
  }, [sendAmplitudeEvent]);

  return (
    <Authorize>
      <Helmet title="Payment Methods" />
      <PageBanners />
      <PageWrapper loading={loading} history={history}>
        {showFeature('creditAccount') &&
          creditAccountMigrationConfig?.paymentMethods?.isCreditAccountBannerVisible && (
            <ReplacePaymentsBanner />
          )}
        <PageTitle title={translateText('corporateAccounts.menuLeft.payments')} />

        {shouldRestrictNumberOfPaymentMethods && (
          <Alert
            type="warning"
            showIcon
            message={translateText(
              `corporateAccounts.payments.create.error.${PAS_FAILURE_ERRORS.RestrictedCorporateClientTotalLimitExceeded}`,
            )}
            className={`${infographStyles.subtext} ${styles.limitReachedWarning}`}
          />
        )}

        <PaymentsEmptyState
          visible={!loading && showNoPaymentUI()}
          handleAddPayment={() => {
            sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.paymentEmptyStateAddButtonClicked);
            paymentHelper.handleNavigateToAddPayment(history);
          }}
        />

        <ButtonSearchRow
          visible={!shouldRestrictNumberOfPaymentMethods && !loading && !showNoPaymentUI()}
          primaryBtn={addPaymentButton()}
        />
        <PageTable
          visible={!loading && !showNoPaymentUI()}
          table={
            <Table
              columns={tableColumns(editSpa, deleteSpa, translateText)}
              dataSource={data}
              rowKey="id"
              pagination={false}
              loading={loading}
              scroll={{
                x: true,
              }}
            />
          }
        />
        {!loading && !showNoPaymentUI() && (
          <Typography.Text className={styles.paymentsDescription}>
            {translateText('corporateAccounts.payments.description')}{' '}
            <a href={'/vehicles'} rel="noreferrer">
              {translateText('corporateAccounts.payments.description.link')}
            </a>
          </Typography.Text>
        )}
      </PageWrapper>
    </Authorize>
  );
};

export default withPusher(injectIntl(PaymentsSpa));
