import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import Authorize from 'components/Providers/Authorize';
import PageWrapper from 'components/Shared/Page/Wrapper';
import PageTitle from 'components/Shared/Page/Title';
import withPusher from 'components/Providers/Pusher/withPusher';
import PageBanners from 'components/Layout/PageBanners';
import useTranslation from 'utils/hooks/useTranslation';
import TableControl from './TableControl';
import InvoiceTable from './InvoiceTable';
import InvoiceModal from './InvoiceModal';
import { AMPLITUDE_EVENT_TYPES, AMPLITUDE_USER_ACTIONS } from 'constants/amplitude';
import useAmplitude from '../../utils/hooks/useAmplitude';
import useInvoiceAndStatementsPage from './useInvoiceAndStatementsPage';

import styles from './style.module.scss';

const InvoicesAndStatements = ({ history }) => {
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();
  const [invoiceId, setInvoiceId] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [summaryUrl, setSummaryUrl] = useState(null);
  const [invoiceUrl, setInvoiceUrl] = useState(null);

  const { invoiceSummaryList, isInvoiceSummaryListLoadingSuccessful } = useInvoiceAndStatementsPage(
    selectedDateRange,
    summaryUrl,
    setSummaryUrl,
    invoiceUrl,
    setInvoiceUrl,
  );

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.invoicesAndStatementsPage, {
      userAction: AMPLITUDE_USER_ACTIONS.pageViewed,
    });
  }, [sendAmplitudeEvent]);

  const checkboxOptions = [
    translateText('corporateAccounts.invoicesAndStatements.modal.summary'),
    translateText('corporateAccounts.invoicesAndStatements.modal.invoice'),
  ];

  const handleInvoiceModalVisibility = async ({ invoiceId }) => {
    setInvoiceId(invoiceId);
  };

  return (
    <Authorize>
      <Helmet title="Invoices and statements" />
      <PageBanners />
      <PageWrapper history={history} loading={false}>
        <PageTitle title={translateText('corporateAccounts.menuLeft.invoicesAndStatements')} />
        <div className="card-body">
          <div className={styles.main}>
            <TableControl setSelectedDateRange={setSelectedDateRange} />
            <InvoiceTable
              handleInvoiceModalVisibility={handleInvoiceModalVisibility}
              tableData={invoiceSummaryList}
              selectedDateRange={selectedDateRange}
              isInvoiceSummaryListLoadingSuccessful={isInvoiceSummaryListLoadingSuccessful}
            />
            <InvoiceModal
              visible={invoiceId !== null}
              loading={false}
              onClose={() => setInvoiceId(null)}
              handleInvoiceModalVisibility={handleInvoiceModalVisibility}
              checkboxOptions={checkboxOptions}
              documentLinks={invoiceSummaryList[invoiceId]?.documents}
              setSummaryUrl={setSummaryUrl}
              setInvoiceUrl={setInvoiceUrl}
            />
          </div>
        </div>
      </PageWrapper>
    </Authorize>
  );
};

export default withPusher(injectIntl(InvoicesAndStatements));
