import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, DatePicker, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { EmptyIcon } from 'components/Shared/Icons';
import isEmpty from 'lodash.isempty';
import actions from 'redux/reports/actions';
import { injectIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import useUpdateEffect from 'utils/hooks/useUpdateEffect';
import utilityModal from 'components/Shared/Modal/info';
import SearchInput from 'components/Shared/Search';
import useTranslation from 'utils/hooks/useTranslation';
import { getDelimiterByCountry } from 'utils/exportUtil';
import { convertCorporateClientNameToShortName } from 'utils/corporateClientUtil';
import useAmplitude from 'utils/hooks/useAmplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { translateReport } from './Exports/exportHelpers';
import { exportHeaders } from './Exports/headers';
import tableColumns from './tableColumns';
import styles from './style.module.scss';

import { Spin } from 'antd';

const PAGE_SIZE = 100;

const { RangePicker } = DatePicker;

const Transactions = ({ intl }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();

  const [[startDate, endDate], setDateRange] = useState([null, null]);
  const [loadClicked, setLoadClicked] = useState(false);
  const fileStartDate = startDate?.toISOString().split('T')[0];
  const fileEndDate = endDate?.toISOString().split('T')[0];

  const searchRef = useRef(null);
  const [query, setQuery] = useState(null);

  const dispatch = useDispatch();
  const reportsSelector = useSelector(state => state.reports);
  const currentCorporation = useSelector(state => state.user.currentCorporation);
  const isFooterRendered =
    currentCorporation.country === 'US' || currentCorporation.country === 'CA';
  const { data, loading } = reportsSelector;

  useEffect(() => {
    dispatch({ type: actions.RESET_ERRORS });
    if (reportsSelector.error) {
      utilityModal.errorModal({
        content: translateText('corporateAccounts.reports.transactions.timeout.modal.content'),
        title: translateText('corporateAccounts.reports.transactions.timeout.modal.title'),
        onOk: () => sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.transactionsError),
      });
    }
  }, [dispatch, sendAmplitudeEvent, reportsSelector.error]); // eslint-disable-line react-hooks/exhaustive-deps

  useUpdateEffect(() => {
    if (!startDate && !endDate) {
      dispatch({ type: actions.RESET_REPORTS });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    return () => dispatch({ type: actions.RESET_REPORTS });
  }, [dispatch]);

  const dateProperties = {
    startDate: moment(startDate)
      .startOf('day')
      .format('YYYY-MM-DD'),
    endDate: moment(endDate)
      .startOf('day')
      .format('YYYY-MM-DD'),
  };

  const handleLoad = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.loadTransactions, dateProperties);
    setLoadClicked(true);

    if (!isEmpty(data)) setQuery(null);

    if (!isEmpty(startDate) && !isEmpty(endDate)) {
      const localStartDate = moment(startDate)
        .startOf('day')
        .format();
      const localEndDate = moment(endDate)
        .endOf('day')
        .format();
      dispatch({
        type: actions.QUERY,
        payload: { localStartDate, localEndDate, query },
      });
    }
  };

  const handleExport = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.exportReport, dateProperties);
  };

  const headers = exportHeaders(intl).map(header => {
    return {
      label: header.id,
      key: header.key,
    };
  });

  return (
    <>
      <div className={styles.tableControl}>
        <div className={styles.tableControlLeft}>
          <RangePicker
            value={[startDate, endDate]}
            onChange={setDateRange}
            placeholder={[
              translateText('corporateAccounts.reports.dateRange.startDate'),
              translateText('corporateAccounts.reports.dateRange.endDate'),
            ]}
            allowClear={false}
          />
          <Button
            type="primary"
            size="large"
            onClick={handleLoad}
            disabled={isEmpty(startDate) || isEmpty(endDate)}
            data-testid="loadButton"
          >
            {translateText('corporateAccounts.reports.actions.load')}
          </Button>
        </div>
        <div className={styles.tableControlRight}>
          <SearchInput
            ref={searchRef}
            onSearchFunc={handleLoad}
            placeholder={translateText('corporateAccounts.reports.searchInput.placeholder')}
            showLengthRequired={true}
            className={styles.searchInputContainer}
            searchClassName={styles.searchInput}
            onChange={setQuery}
          />
          <CSVLink
            data={translateReport(data || [], intl)}
            filename={`${currentCorporation &&
              convertCorporateClientNameToShortName(
                currentCorporation.corporateClientName,
              )} - ${fileStartDate} - ${fileEndDate} - Expense Report.csv`}
            headers={headers}
            separator={getDelimiterByCountry(currentCorporation && currentCorporation?.country)}
          >
            <Button
              type="primary"
              size="large"
              disabled={isEmpty(data)}
              onClick={handleExport}
              data-testid="exportButton"
            >
              <DownloadOutlined />
              {translateText('corporateAccounts.actions.export')}
            </Button>
          </CSVLink>
        </div>
      </div>

      {!isEmpty(data) ? (
        <div>
          <Table
            scroll={{ x: 'max-content', y: 500 }}
            className="utils__scrollTable card-body-table-padding-top"
            columns={tableColumns(translateText)}
            dataSource={data}
            rowKey="transactionId"
            pagination={{ defaultPageSize: PAGE_SIZE, showSizeChanger: true }}
            loading={loading}
            bordered
            sticky
            data-testid="transactionTable"
          />
          {isFooterRendered && (
            <Typography>
              {translateText('corporateAccounts.reports.transactions.footer')}
            </Typography>
          )}
        </div>
      ) : loading ? (
        <Spin className={styles.spinner} />
      ) : !loadClicked ? (
        <div className={styles.emptyState}>
          <EmptyIcon />
          {translateText('corporateAccounts.reports.transactions.chooseDateRange')}
        </div>
      ) : (
        <div className={styles.emptyState}>
          <EmptyIcon />
          {translateText('corporateAccounts.reports.transactions.noResults')}
        </div>
      )}
    </>
  );
};

export default injectIntl(Transactions);
