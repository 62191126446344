import Icon from '@ant-design/icons';
import { CircleIcon } from 'components/Shared/Icons';
import { localizedCurrency } from 'services/localize';
import useTranslation from 'utils/hooks/useTranslation';
import React from 'react';
import { REPORT_VALID_DAYS } from 'constants/reports';
import tableUtil from 'utils/tableUtil';
import { DAYS_OF_WEEK } from 'constants.js';
import { formatDateTime } from './helper';

const DayCell = ({ day }) => {
  const { translateText } = useTranslation();
  const isValidDay = REPORT_VALID_DAYS.includes(day);
  const backgroundColor = isValidDay ? '#75c044' : '#c9342d';
  const iconStyle = {
    height: '10px',
    width: '10px',
    fill: backgroundColor,
    marginRight: '6px',
  };

  return (
    <div>
      <Icon
        component={CircleIcon}
        style={iconStyle}
        data-testid={isValidDay ? 'validDayIcon' : 'invalidDayIcon'}
      />
      <span>{translateText(`corporateAccounts.daysOfWeek.${day}`)}</span>
    </div>
  );
};

const tableColumns = translateText => [
  {
    title: translateText('corporateAccounts.reports.paymentDate'),
    dataIndex: 'paymentDateLocal',
    sorter: (a, b) => tableUtil.stringSort(a, b, 'paymentDateLocal'),
    render: value => formatDateTime(value),
    width: 160,
  },
  {
    title: translateText('corporateAccounts.reports.localStartDateTime'),
    dataIndex: 'localStartDateTime',
    sorter: (a, b) => tableUtil.stringSort(a, b, 'localStartDateTime'),
    render: value => formatDateTime(value),
    width: 160,
  },
  {
    title: translateText('corporateAccounts.reports.localEndDateTime'),
    dataIndex: 'localEndDateTime',
    sorter: (a, b) => tableUtil.stringSort(a, b, 'localEndDateTime'),
    render: value => formatDateTime(value),
    width: 150,
  },
  {
    title: translateText('corporateAccounts.reports.parkingMinutes'),
    dataIndex: 'duration',
    sorter: (a, b) => tableUtil.stringNumberSort(a, b, 'duration'),
    width: 150,
  },
  {
    title: translateText('corporateAccounts.reports.day'),
    dataIndex: 'dayOfWeek',
    key: 'dayOfWeek',
    filters: Object.values(DAYS_OF_WEEK).map(dayOfWeek => {
      return {
        text: translateText(`corporateAccounts.daysOfWeek.${dayOfWeek}`),
        value: dayOfWeek,
      };
    }),
    onFilter: (value, record) => {
      return value === record.dayOfWeek;
    },
    render: day => <DayCell day={day} />,
    width: 100,
  },
  {
    title: translateText('corporateAccounts.reports.licensePlate'),
    dataIndex: 'licensePlate',
    width: 150,
  },
  {
    title: translateText('corporateAccounts.reports.firstName'),
    dataIndex: 'firstName',
    width: 100,
    render: firstName => {
      return tableUtil.emptyValueFallback(firstName, translateText);
    },
  },
  {
    title: translateText('corporateAccounts.reports.lastName'),
    dataIndex: 'lastName',
    width: 100,
    render: lastName => {
      return tableUtil.emptyValueFallback(lastName, translateText);
    },
  },
  {
    title: translateText('corporateAccounts.reports.phoneNumber'),
    dataIndex: 'phoneNumber',
    width: 150,
  },
  {
    title: translateText('corporateAccounts.reports.netParkingAmount'),
    dataIndex: 'netParkingAmount',
    sorter: (a, b) => tableUtil.stringNumberSort(a, b, 'netParkingAmount'),
    render: (value, row) => localizedCurrency(value, row.currency),
    width: 200,
  },
  {
    title: translateText('corporateAccounts.reports.netTransactionFee'),
    dataIndex: 'netTransactionFee',
    sorter: (a, b) => tableUtil.stringNumberSort(a, b, 'netTransactionFee'),
    render: (value, row) => localizedCurrency(value, row.currency),
    width: 200,
  },
  {
    title: translateText('corporateAccounts.reports.netConfirmationFee'),
    dataIndex: 'netConfirmationFee',
    sorter: (a, b) => tableUtil.stringNumberSort(a, b, 'NetConfirmationFee'),
    render: (value, row) => localizedCurrency(value, row.currency),
    width: 200,
  },
  {
    title: translateText('corporateAccounts.reports.netReminderFee'),
    dataIndex: 'netReminderFee',
    sorter: (a, b) => tableUtil.stringNumberSort(a, b, 'netReminderFee'),
    render: (value, row) => localizedCurrency(value, row.currency),
    width: 200,
  },
  {
    title: translateText('corporateAccounts.reports.netTotal'),
    dataIndex: 'netTotal',
    sorter: (a, b) => tableUtil.stringNumberSort(a, b, 'netTotal'),
    render: (value, row) => localizedCurrency(value, row.currency),
    width: 140,
  },
  {
    title: translateText('corporateAccounts.reports.tax'),
    dataIndex: 'tax',
    render: (value, row) => localizedCurrency(value, row.currency),
    width: 70,
  },
  {
    title: translateText('corporateAccounts.reports.cost'),
    dataIndex: 'cost',
    sorter: (a, b) => tableUtil.stringNumberSort(a, b, 'cost'),
    render: (value, row) => localizedCurrency(value, row.currency),
    width: 120,
  },
  {
    title: translateText('corporateAccounts.reports.parkingVendorName'),
    dataIndex: 'parkingVendorName',
    width: 230,
  },
  {
    title: translateText('corporateAccounts.reports.parkingLotName'),
    dataIndex: 'parkingLotName',
    width: 200,
  },
  {
    title: translateText('corporateAccounts.reports.lotId'),
    dataIndex: 'lotId',
    width: 140,
  },
  {
    title: translateText('corporateAccounts.reports.transactionId'),
    dataIndex: 'transactionId',
    width: 150,
  },
  {
    title: translateText('corporateAccounts.reports.email'),
    dataIndex: 'email',
    width: 70,
  },
  {
    title: translateText('corporateAccounts.drivers.costCenter'),
    dataIndex: 'costCenter',
    width: 150,
  },
  {
    title: translateText('corporateAccounts.reports.paymentStatus'),
    dataIndex: 'paymentStatus',
    width: 150,
  },
  {
    title: translateText('corporateAccounts.reports.maskedCardNumber'),
    dataIndex: 'maskedCardNumber',
    width: 260,
  },
];

export default tableColumns;
