import { useEffect, useCallback } from 'react';
import { RECAPTCHA_V3_SITE_KEY } from 'constants.js';
import { useSelector, useDispatch } from 'react-redux';

const useRecaptcha = () => {
  const { isRecaptchaLoaded } = useSelector(state => state.settings);
  const dispatch = useDispatch();

  const setIsRecaptchaLoaded = useCallback(
    value => {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isRecaptchaLoaded',
          value,
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (window.grecaptcha) {
      setIsRecaptchaLoaded(true);
    } else {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_V3_SITE_KEY}`;
      script.async = true;

      script.onload = () => {
        setIsRecaptchaLoaded(true);
      };

      script.onerror = error => {
        console.error('Error loading reCAPTCHA script', error);
      };

      document.head.appendChild(script);

      return () => {
        setIsRecaptchaLoaded(false);
        document.head.removeChild(script);

        const recaptchaBadge = document.querySelector('.grecaptcha-badge');
        if (recaptchaBadge) {
          recaptchaBadge.remove();
        }

        if (window.grecaptcha) {
          delete window.grecaptcha;
        }
      };
    }
  }, [setIsRecaptchaLoaded]);

  const executeRecaptcha = useCallback(
    async action => {
      if (isRecaptchaLoaded) {
        return await window.grecaptcha.enterprise.execute(RECAPTCHA_V3_SITE_KEY, {
          action,
        });
      } else {
        console.error('reCAPTCHA is not loaded');
        return null;
      }
    },
    [isRecaptchaLoaded],
  );

  return { executeRecaptcha };
};

export default useRecaptcha;
