import { SUPPORTED_COUNTRIES } from 'constants.js';
import { Modal } from 'antd';
import getCountryLabel from 'utils/countryLabelUtil';
import { CORPORATE_CLIENT_STATUS } from 'constants/corporateClients';
import { ADMIN_USER_INVITE_STATUS } from 'constants.js';
import {
  CORPORATE_CLIENT_STATUS_STRING_TAGS,
  TEST_CORPORATION_STATUS_TAGS,
} from 'constants/corporateClients';

const canDeactivate = corporation => {
  return corporation.status === CORPORATE_CLIENT_STATUS.ACTIVE || corporation.status === CORPORATE_CLIENT_STATUS.PROSPECT;
};

const canReactivate = corporation => {
  return corporation.status === CORPORATE_CLIENT_STATUS.DEACTIVATED;
};

const canDelete = corporation => true;

const deactivateCorporation = (corporation, onDeactivate, translateText) => {
  Modal.confirm({
    content: translateText('corporateAccounts.admins.deactivate.modal.confirm.body', {
      corporateClientName: corporation.corporateClientName,
    }),
    title: translateText('corporateAccounts.admins.deactivate.modal.confirm.title'),
    onOk() {
      onDeactivate(corporation);
    },
    okText: translateText('corporateAccounts.actions.deactivateAccount'),
    okType: 'danger',
    cancelText: translateText('corporateAccounts.actions.cancel'),
    onCancel() {},
  });
};

const buildCountryFilter = translateText => {
  return SUPPORTED_COUNTRIES.map(supportedCountry => ({
    text: getCountryLabel(supportedCountry, translateText),
    value: supportedCountry.countryCode,
  }));
};

const reactivateCorporation = (corporation, onReactivate, translateText) => {
  Modal.confirm({
    content: translateText('corporateAccounts.admins.reactivate.modal.confirm.body', {
      corporateClientName: corporation.corporateClientName,
    }),
    title: translateText('corporateAccounts.admins.reactivate.modal.confirm.title'),
    onOk() {
      onReactivate(corporation);
    },
    okText: translateText('corporateAccounts.actions.reactivateAccount'),
    okType: 'danger',
    cancelText: translateText('corporateAccounts.actions.cancel'),
    onCancel() {},
  });
};

const deleteCorporation = (onDeleteCorporation, corporation, translateText) => {
  Modal.confirm({
    content: translateText('corporateAccounts.admins.delete.modal.confirm.body', {
      corporateClientName: corporation.corporateClientName,
    }),
    title: translateText('corporateAccounts.admins.delete.modal.confirm.title'),
    onOk() {
      onDeleteCorporation(corporation);
    },
    okText: translateText('corporateAccounts.actions.delete'),
    okType: 'danger',
    cancelText: translateText('corporateAccounts.actions.cancel'),
    onCancel() {},
  });
};

// This function has been added temporarily to reset all filters if all options are selected, until the backend supports selecting all filters.
export const resetFiltersOnAllSelect = (filters, translateText) => {
  filters.country?.length === buildCountryFilter(translateText).length &&
    (filters = { ...filters, country: null });

  filters.isTestCorporation?.length === Object.values(TEST_CORPORATION_STATUS_TAGS).length &&
    (filters = { ...filters, isTestCorporation: null });

  filters.status?.length === Object.values(CORPORATE_CLIENT_STATUS_STRING_TAGS).length &&
    (filters = { ...filters, status: null });

  filters.accountOwnerStatus?.length === ADMIN_USER_INVITE_STATUS.length &&
    (filters = { ...filters, accountOwnerStatus: null });

  return filters;
};

export const toCamelCase = string => string?.charAt(0).toLowerCase() + string?.slice(1);

const corporationAdminHelper = {
  buildCountryFilter,
  canDeactivate,
  canReactivate,
  canDelete,
  deactivateCorporation,
  reactivateCorporation,
  deleteCorporation,
  resetFiltersOnAllSelect,
};

export default corporationAdminHelper;
