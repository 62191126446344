import React from 'react';
import DeactivateSelfRegisteredModal from './DeactivateSelfRegisteredModal';
import DeactivateEnterpriseModal from './DeactivateEnterpriseModal';
import DeactivateConfirmModal from './DeactivateConfirmModal';

const DeactivationModals = ({
  showDeactivateModal,
  isSelfRegistered,
  userDeactivateSelection,
  setUserDeactivateSelection,
  setShowDeactivateModal,
  currentCorporation,
}) => {
  return (
    <div>
      <DeactivateSelfRegisteredModal
        isModalVisible={showDeactivateModal && isSelfRegistered && !userDeactivateSelection}
        setUserDeactivateSelection={setUserDeactivateSelection}
        setIsModalVisible={setShowDeactivateModal}
      />

      <DeactivateEnterpriseModal
        isModalVisible={showDeactivateModal && !isSelfRegistered}
        setIsModalVisible={setShowDeactivateModal}
        setUserDeactivateSelection={setUserDeactivateSelection}
      />

      <DeactivateConfirmModal
        isModalVisible={showDeactivateModal && userDeactivateSelection}
        setIsModalVisible={setShowDeactivateModal}
        setUserDeactivateSelection={setUserDeactivateSelection}
        userDeactivateSelection={userDeactivateSelection}
        currentCorporation={currentCorporation}
      />
    </div>
  );
};

export default DeactivationModals;
