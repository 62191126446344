import React from 'react';
import { Typography, Divider, Tooltip } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import {
  creditAccountTypeConfigurations,
  creditAccountValueMapper,
} from './creditAccountTypeConfigurations';
import { CopyToClipboardIcon, AllstarIcon, InfoIcon } from 'components/Shared/Icons';

import styles from './style.module.scss';

const { Title, Text } = Typography;

const CreditAccountDetails = ({ creditAccountDetails }) => {
  const { translateText } = useTranslation();

  const callToActions = {
    update: () => (
      <div>
        <a
          href="https://google.com"
          target="_blank"
          rel="noreferrer"
          className={styles.buttonAsLink}
        >
          {translateText('corporateAccounts.common.update')}
        </a>
      </div>
    ),
    copyToClipboard: value => (
      <a
        onClick={() => {
          navigator.clipboard.writeText(value);
        }}
      >
        <CopyToClipboardIcon />
      </a>
    ),
  };

  const displayCreditAccountDetails = creditAccountFields =>
    creditAccountFields.map(([key, detailItem]) => {
      const fieldName = detailItem.name;
      const defaultValue = detailItem.defaultValue;
      const cta = detailItem.cta;

      const getFieldValue = () => {
        const fieldValue = creditAccountDetails[creditAccountValueMapper[fieldName]];

        const getPaymentMethodDetails = (lastFourDigits, expiryDate) => {
          return lastFourDigits && expiryDate
            ? translateText('corporateAccounts.creditAccount.details.expiryDate', {
                cardNumber: '***' + lastFourDigits,
                expiryDate: expiryDate,
              })
            : defaultValue;
        };

        if (fieldName === 'overLimitPaymentMethod') {
          const { lastFourDigits, expiryDate } = creditAccountDetails?.overLimitPaymentMethod || {};
          return getPaymentMethodDetails(lastFourDigits, expiryDate);
        }

        if (
          fieldName === 'paymentMethod' &&
          creditAccountDetails.paymentMethodType === 'creditCard'
        ) {
          const { lastFourDigits, expiryDate } = creditAccountDetails || {};
          return getPaymentMethodDetails(lastFourDigits, expiryDate);
        }

        if (!fieldValue) return defaultValue;

        return fieldName === 'invoicingFrequency'
          ? translateText(`corporateAccounts.common.frequency.${fieldValue}`)
          : fieldName === 'paymentMethod'
          ? translateText(`corporateAccounts.common.${creditAccountDetails.paymentMethodType}`)
          : fieldValue;
      };

      return (
        <div key={key} className={styles.detailItem}>
          <div>
            <span>{translateText(`corporateAccounts.creditAccount.details.${fieldName}`)}</span>
            <span>:</span>
          </div>
          <span className={styles.detailItemValue}>
            <Text
              style={
                fieldName === 'paymentAmountDue' ? { fontSize: '24px', lineHeight: '0.9' } : {}
              }
            >
              {getFieldValue()}
            </Text>
            {cta &&
              creditAccountDetails[fieldName] !== defaultValue &&
              callToActions[cta](creditAccountDetails[creditAccountValueMapper[fieldName]])}
          </span>
        </div>
      );
    });

  return (
    <div className={styles.detailsMainContainer}>
      <section className={styles.detailsCurrentSpend}>
        <div className={styles.detailsCurrentSpendLeft}>
          <Tooltip
            title={translateText('corporateAccounts.creditAccount.details.toolTip')}
            className={styles.detailsCurrentSpendTitle}
            arrow="target"
            placement="rightTop"
          >
            <Title level={5}>
              <span>{translateText('corporateAccounts.creditAccount.details.currentSpend')}</span>
              <span>:</span>
            </Title>
            <InfoIcon color="#808080" />
          </Tooltip>
          <Title level={1}>{creditAccountDetails.currentSpend}</Title>
          <div>
            <Text className={styles.detialsLastUpdated}>
              <span>{translateText('corporateAccounts.creditAccount.details.lastUpdated')}</span>
              <span>:&nbsp;</span>
            </Text>
            <Text className={styles.detialsLastUpdated}>{creditAccountDetails.lastUpdated}</Text>
          </div>
        </div>
        <AllstarIcon />
      </section>

      <section className={styles.detailsSection}>
        <Title level={5}>
          {translateText('corporateAccounts.creditAccount.details.creditAccountDetails')}
        </Title>
        {displayCreditAccountDetails(
          Object.entries(
            creditAccountTypeConfigurations[creditAccountDetails.creditAccountType]
              .creditAccountDetails,
          ),
        )}
      </section>

      <Divider className={styles.divider} />

      <section className={styles.detailsSection}>
        <Title level={5}>
          {translateText('corporateAccounts.creditAccount.details.paymentDetails')}
        </Title>
        {displayCreditAccountDetails(
          Object.entries(
            creditAccountTypeConfigurations[creditAccountDetails.creditAccountType].paymentDetails,
          ),
        )}
      </section>
    </div>
  );
};

export default CreditAccountDetails;
