import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs';

const DatadogLogsProvider = ({ children }) => {
  const currentUser = useSelector(state => state.user);
  const { id, corporateClientId, corporationRole, currentCorporation } = currentUser;

  useEffect(() => {
    datadogLogs.setGlobalContextProperty('corporateClientId', corporateClientId);
    datadogLogs.setGlobalContextProperty('userAccountId', id);
    datadogLogs.setGlobalContextProperty('userRole', corporationRole);
  }, [currentUser, id, corporateClientId, corporationRole, currentCorporation]);

  return <>{children}</>;
};

export default DatadogLogsProvider;
