const correctLength = /^.{8,20}$/;
const includesNumber = /\d/;
const includesLowercase = /[a-z]/;
const includesUppercase = /[A-Z]/;
const includesSpecialCharacter = /[^a-zA-Z\d]/;

const enforceCorporateAccountsPasswordPolicies = (password, translateText) => {
  if (!correctLength.test(password)) {
    return Promise.reject(translateText('corporateAccounts.passwordValidation.lengthError'));
  }

  if (!includesLowercase.test(password)) {
    return Promise.reject(translateText('corporateAccounts.passwordValidation.lowerCaseError'));
  }

  if (!includesUppercase.test(password)) {
    return Promise.reject(translateText('corporateAccounts.passwordValidation.upperCaseError'));
  }

  if (!includesNumber.test(password)) {
    return Promise.reject(translateText('corporateAccounts.passwordValidation.missingNumberError'));
  }

  if (!includesSpecialCharacter.test(password)) {
    return Promise.reject(
      translateText('corporateAccounts.passwordValidation.missingSpecialCharacterError'),
    );
  }

  return Promise.resolve('Password is valid');
};

const enforceDriverGlobalPasswordPolicies = (password, translateText) => {
  const GLOBAL_PASS_POLICY = /^[a-zA-Z0-9<>,\.\?/;:'""\\\|\]}\[\{~`!@#\$%\^&\*\(\)_\+=-]{6,20}$/;

  if (!GLOBAL_PASS_POLICY.test(password)) {
    return Promise.reject(translateText('corporateAccounts.passwordValidation.defaultError'));
  }

  return Promise.resolve('Password is valid');
};

export const isValidDriverPassword = async (password, translateText) => {
  try {
    await enforceCorporateAccountsPasswordPolicies(password, translateText);
    await enforceDriverGlobalPasswordPolicies(password, translateText);

    return Promise.resolve('Password is valid');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkStaffUserPasswordPolicies = (password, translateText) => {
  const validationResults = {
    length: {
      message: translateText('corporateAccounts.common.password.length'),
      isValid: correctLength.test(password),
    },
    number: {
      message: translateText('corporateAccounts.common.password.number'),
      isValid: includesNumber.test(password),
    },
    lowercase: {
      message: translateText('corporateAccounts.common.password.lowercase'),
      isValid: includesLowercase.test(password),
    },
    symbol: {
      message: translateText('corporateAccounts.common.password.specialChar'),
      isValid: includesSpecialCharacter.test(password),
    },
    uppercase: {
      message: translateText('corporateAccounts.common.password.uppercase'),
      isValid: includesUppercase.test(password),
    },
  };

  return validationResults;
};
