import React from 'react';
import { Button, Typography } from 'antd';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import useTranslation from 'utils/hooks/useTranslation';
import { VerificationLinkExpiredIcon } from 'components/Shared/Icons';

import styles from './style.module.scss';

const VerificationLinkExpired = ({ onResendEmail }) => {
  const { translateText } = useTranslation();
  const { executeRecaptcha } = useRecaptcha();

  const handleResendEmail = async () => {
    const recaptchaToken = await executeRecaptcha('submit');
    onResendEmail({ recaptchaToken });
  };

  return (
    <div className={styles.verificationPage} data-testid="verificationLinkExpired">
      <div data-testid="verificationLinkExpiredIcon">
        <VerificationLinkExpiredIcon />
      </div>
      <Typography.Title level={2} className={styles.verificationTitle}>
        {translateText('corporateAccounts.selfRegistration.verification.linkExpired')}
      </Typography.Title>
      <Typography className={styles.verificationMessage}>
        <Typography.Text>
          {translateText('corporateAccounts.selfRegistration.verification.linkExpired.message')}
        </Typography.Text>
      </Typography>
      <div className={styles.verificationButton}>
        <Button
          type="primary"
          onClick={() => handleResendEmail()}
          data-testid={'resendEmailButton'}
        >
          <p>
            {translateText('corporateAccounts.selfRegistration.verification.resendVerification')}
          </p>
        </Button>
      </div>
    </div>
  );
};

export default VerificationLinkExpired;
