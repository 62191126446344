import React, { useState } from 'react';
import { Modal, Form, Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import DriverInvitationModalContent from './DriverInvitationModalContent';

const DriverInvitationModal = ({ visible, onClose, onConfirmInvite, driverIds, isBulkInvite }) => {
  const { translateText } = useTranslation();
  const { executeRecaptcha } = useRecaptcha();
  const [form] = Form.useForm();
  const [invitationByEmail, setInvitationByEmail] = useState(false);
  const [invitationBySms, setInvitationBySms] = useState(false);

  const handleOk = async () => {
    const recaptchaToken = await executeRecaptcha('submit');
    const values = { invitationByEmail, invitationBySms, DriverIds: driverIds, recaptchaToken };
    onConfirmInvite(values);
    handleClose();
  };

  const handleClose = () => {
    setInvitationByEmail(false);
    setInvitationBySms(false);
    form.resetFields();
    onClose();
  };

  const handleSmsChange = () => {
    setInvitationBySms(!invitationBySms);
  };

  const handleEmailChange = () => {
    setInvitationByEmail(!invitationByEmail);
  };

  return (
    <Modal
      title={
        <Typography component="h2">
          {isBulkInvite
            ? translateText('corporateAccounts.drivers.bulkResendInviteModal.title')
            : translateText('corporateAccounts.actions.resendInvite')}
        </Typography>
      }
      okText={translateText('corporateAccounts.actions.resendInvite')}
      cancelText={translateText('corporateAccounts.actions.cancel')}
      width={480}
      visible={visible}
      centered
      closable
      onOk={handleOk}
      onCancel={handleClose}
      okButtonProps={{
        disabled: !(invitationByEmail || invitationBySms),
        'data-testid': 'inviteBtn',
      }}
    >
      <DriverInvitationModalContent
        handleSmsChange={handleSmsChange}
        handleEmailChange={handleEmailChange}
        isBulkInvite={isBulkInvite}
        form={form}
      />
    </Modal>
  );
};

export default DriverInvitationModal;
