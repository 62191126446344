import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedNumber, injectIntl } from 'react-intl';
import { Button, Typography } from 'antd';
import useAmplitude from 'utils/hooks/useAmplitude';
import useGetSupportLink from 'utils/hooks/useGetSupportLink';
import useTranslation from 'utils/hooks/useTranslation';
import { getBrowserCountryBasedOnLanguage } from 'utils/browserLanguageCountryUtil';
import { AMPLITUDE_EVENT_TYPES, AMPLITUDE_USER_ACTIONS } from 'constants/amplitude';
import Spinner from 'components/Shared/Spinner';
import { COUNTRY_LINKS } from 'constants.js';
import StatusIndicator from './StatusIndicator';
import DeactivationModals from './Modals';
import { formatDateToMonthAndYear, getNextBillingCycle } from './helpers';
import styles from './style.module.scss';
import Can from 'config/Can';

const { Text, Title, Paragraph } = Typography;

const PricePlanInfoCell = ({ titleText, item }) => {
  return (
    <td className={styles.pricePlanInfoCell}>
      <div className={styles.pricePlanInfoCellData}>
        <Text>{titleText}</Text>
        {item}
      </div>
    </td>
  );
};

const PricePlanInfoText = ({ children }) => {
  return (
    <Title level={3} className={styles.pricePlanInfoText}>
      {children}
    </Title>
  );
};

const Subscription = ({ pricePlan, isPricePlanLoading, isPricePlanError, isSelfRegistered }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const currentCorporation = useSelector(state => state.user.currentCorporation);

  const supportLink = useGetSupportLink();
  const { translateText } = useTranslation();

  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [userDeactivateSelection, setUserDeactivateSelection] = useState(null);

  const locale = useSelector(state => state.settings.locale);
  const browserCountry = getBrowserCountryBasedOnLanguage(locale);

  const memberSince = formatDateToMonthAndYear(currentCorporation.createdAt);
  const nextBillingCycle = formatDateToMonthAndYear(getNextBillingCycle(Date.now()));

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.billingSubscriptionTab);
  }, [sendAmplitudeEvent]);

  return (
    <div className={styles.subscriptionContent}>
      <div className={styles.subscriptionHeader}>
        <Title level={4} className={styles.title}>
          PayByPhone Business
        </Title>
        <Button
          className={styles.editPlan}
          data-testid="contactSalesBtn"
          type="primary"
          onClick={() => {
            window.open(supportLink, '_blank');
            sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.subscriptionContactSalesBtn);
          }}
        >
          {translateText('corporateAccounts.subscription.editPlan')}
        </Button>
      </div>

      {isPricePlanLoading && <Spinner size={48} />}
      {!isPricePlanLoading && (
        <table className={`${styles.pricePlanInfo} ${isSelfRegistered ? '' : styles.half}`}>
          <tbody>
            <tr>
              <PricePlanInfoCell
                titleText={translateText('corporateAccounts.common.status')}
                item={<StatusIndicator corporationStatusName={currentCorporation.statusName} />}
              />
              <PricePlanInfoCell
                titleText={translateText('corporateAccounts.subscription.monthlyPlan')}
                item={
                  <>
                    {isSelfRegistered && (
                      <>
                        {(isPricePlanError || !pricePlan) && <Text>-</Text>}

                        {!isPricePlanError && pricePlan && (
                          <PricePlanInfoText>
                            <FormattedNumber
                              value={pricePlan?.unitPrice}
                              style="currency" // eslint-disable-line
                              currency={pricePlan?.currencyCode}
                            />{' '}
                            <Text className={styles.perDriver}>{`(${translateText(
                              'corporateAccounts.subscription.perDriver',
                            )})`}</Text>
                          </PricePlanInfoText>
                        )}
                      </>
                    )}

                    {!isSelfRegistered && (
                      <Text className={styles.contactForPricing}>
                        {translateText('corporateAccounts.subscription.contactForPricing')}
                      </Text>
                    )}
                  </>
                }
              />
              {isSelfRegistered && (
                <PricePlanInfoCell
                  titleText={translateText('corporateAccounts.subscription.nextBillingCycle')}
                  item={
                    <>
                      {(isPricePlanError || !pricePlan) && <Text>-</Text>}
                      {!isPricePlanError && pricePlan && (
                        <PricePlanInfoText>{nextBillingCycle}</PricePlanInfoText>
                      )}
                    </>
                  }
                />
              )}
              {isSelfRegistered && (
                <PricePlanInfoCell
                  titleText={translateText('corporateAccounts.subscription.memberSince')}
                  item={
                    <>
                      {(isPricePlanError || !pricePlan) && <Text>-</Text>}
                      {!isPricePlanError && pricePlan && (
                        <PricePlanInfoText>{memberSince}</PricePlanInfoText>
                      )}
                    </>
                  }
                />
              )}
            </tr>
          </tbody>
        </table>
      )}

      <div>
        <Paragraph className={styles.subscriptionAgreement}>
          {translateText('corporateAccounts.subscription.terms', {
            termsAndConditions: (
              <a
                href={COUNTRY_LINKS[browserCountry]?.subscriptionAgreement}
                target="_blank"
                rel="noopener noreferrer"
              >
                {translateText('corporateAccounts.common.termsAndConditions')}
              </a>
            ),
            privacyPolicy: (
              <a
                href={COUNTRY_LINKS[browserCountry]?.privacyPolicy}
                target="_blank"
                rel="noopener noreferrer"
              >
                {translateText('corporateAccounts.common.privacyPolicy')}
              </a>
            ),
          })}
        </Paragraph>

        <br />
        <Can I="deactivate" a="Billing" passThrough>
          {can => (
            <div>
              {can && (
                <>
                  <div className={styles.deativateButton}>
                    <Button
                      size="large"
                      data-testid="deactivateAccountBtn"
                      onClick={() => {
                        setShowDeactivateModal(true);
                        sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.subscriptionTab, {
                          userAction: AMPLITUDE_USER_ACTIONS.deactivate,
                        });
                      }}
                    >
                      {translateText('corporateAccounts.subscription.deactivateAccount')}
                    </Button>
                  </div>

                  <DeactivationModals
                    showDeactivateModal={showDeactivateModal && can}
                    isSelfRegistered={isSelfRegistered}
                    userDeactivateSelection={userDeactivateSelection}
                    setUserDeactivateSelection={setUserDeactivateSelection}
                    setShowDeactivateModal={setShowDeactivateModal}
                    currentCorporation={currentCorporation}
                  />
                </>
              )}
            </div>
          )}
        </Can>
      </div>
    </div>
  );
};

export default injectIntl(Subscription);
