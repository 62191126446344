import React from 'react';
import { Button, Typography } from 'antd';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useTranslation from 'utils/hooks/useTranslation';
import ExtendedModal from 'components/Shared/Modal/index.js';
import { useDeactivateCorporation } from 'services/reactQueryHooks/corporations/index';
import { useDispatch } from 'react-redux';
import useAmplitude from 'utils/hooks/useAmplitude';

import styles from './style.module.scss';

const DeactivateConfirmModal = ({
  isModalVisible,
  setIsModalVisible,
  setUserDeactivateSelection,
  userDeactivateSelection,
  currentCorporation,
}) => {
  const { translateText } = useTranslation();
  const dispatch = useDispatch();
  const { sendAmplitudeEvent } = useAmplitude();
  const {
    mutateAsync: deactivateCorporation,
    isLoading: isDeactivateLoading,
  } = useDeactivateCorporation();
  const { Title } = Typography;

  const handleDeactivate = async () => {
    try {
      await deactivateCorporation(currentCorporation);
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.subscriptionTab, {
        userAction: userDeactivateSelection,
      });
      dispatch({ type: 'user/LOGOUT' });
    } catch (e) {
      setIsModalVisible(false);
    }
  };

  return (
    <ExtendedModal
      width={555}
      visible={isModalVisible}
      centered
      closable={false}
      footer={null}
      onCancel={() => {
        setUserDeactivateSelection(null);
        setIsModalVisible(false);
      }}
    >
      <div className={styles.deactivateConfirmModalContent}>
        <Title level={3} className={styles.deactivateModalTitle}>
          {translateText('corporateAccounts.subscription.selfRegisteredDeactivate.confirm.title')}
        </Title>
        <Typography.Paragraph>
          <p>
            {translateText('corporateAccounts.subscription.selfRegisteredDeactivate.confirm.text')}
          </p>
        </Typography.Paragraph>
        <div className={styles.confirmButton}>
          <Button
            data-testid="deactivateAccountConfirmBtn"
            block
            size="large"
            loading={isDeactivateLoading}
            onClick={handleDeactivate}
          >
            {translateText(
              'corporateAccounts.subscription.selfRegisteredDeactivate.confirm.button',
            )}
          </Button>
        </div>
        <div className={styles.cancelButton}>
          <Button
            block
            size="large"
            onClick={() => {
              setUserDeactivateSelection(null);
              setIsModalVisible(false);
            }}
          >
            {translateText('corporateAccounts.actions.cancel')}
          </Button>
        </div>
      </div>
    </ExtendedModal>
  );
};

export default DeactivateConfirmModal;
