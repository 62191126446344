import React from 'react';
import { Button, Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import useGetSupportLink from 'utils/hooks/useGetSupportLink';
import ExtendedModal from 'components/Shared/Modal/index.js';

import styles from './style.module.scss';

const DeactivateEnterpriseModal = ({
  isModalVisible,
  setIsModalVisible,
  setUserDeactivateSelection,
}) => {
  const { translateText } = useTranslation();
  const supportLink = useGetSupportLink();
  const { Title } = Typography;
  return (
    <ExtendedModal
      width={555}
      visible={isModalVisible}
      centered
      closable={false}
      footer={null}
      onCancel={() => {
        setUserDeactivateSelection(null);
        setIsModalVisible(false);
      }}
    >
      {
        <div className={styles.deactivateEnterpriseModalContent}>
          <Title level={3} className={styles.deactivateModalTitle}>
            {translateText('corporateAccounts.subscription.enterpriseDeactivate.title')}
          </Title>
          <Typography.Paragraph>
            <p>{translateText('corporateAccounts.subscription.enterpriseDeactivate.text')}</p>
          </Typography.Paragraph>
          <a className={styles.confirmButton} href={supportLink} target="_blank" rel="noreferrer">
            <Button size="large" block>
              {translateText('corporateAccounts.subscription.enterpriseDeactivate.contactSupport')}
            </Button>
          </a>
          <div className={styles.cancelButton}>
            <Button
              size="large"
              block
              onClick={() => {
                setUserDeactivateSelection(null);
                setIsModalVisible(false);
              }}
            >
              {translateText('corporateAccounts.actions.cancel')}
            </Button>
          </div>
        </div>
      }
    </ExtendedModal>
  );
};

export default DeactivateEnterpriseModal;
