// When enabling the SMS reminder feature flag (smsReminder), make sure to update these dates as well.

export const activationDateByCountry = {
  CA: 'December 10, 2025',
  US: 'December 11, 2025',
  GB: 'December 12, 2025',
  FR: 'December 13, 2025',
  CH: 'December 14, 2025',
  DE: 'December 15, 2025',
};

export const optOutDatesByCountry = {
  CA: 'November 20, 2025',
  US: 'November 21, 2025',
  GB: 'November 22, 2025',
  FR: 'November 23, 2025',
  CH: 'November 24, 2025',
  DE: 'November 25, 2025',
};

export const feeRangesByCountry = {
  CA: {
    lowestFee: '0.01',
    highestFee: '0.21',
  },
  US: {
    lowestFee: '0.02',
    highestFee: '0.22',
  },
  GB: {
    lowestFee: '0.03',
    highestFee: '0.23',
  },
  FR: {
    lowestFee: '0.04',
    highestFee: '0.24',
  },
  CH: {
    lowestFee: '0.05',
    highestFee: '0.25',
  },
  DE: {
    lowestFee: '0.06',
    highestFee: '0.26',
  },
};

export const feeGuideLinksByCountry = {
  CA: 'https://www.paybyphone.com/sms-fees',
  US: 'https://www.paybyphone.com/sms-fees',
  GB: 'https://www.paybyphone.co.uk/fees',
  FR: 'https://www.paybyphone.fr/options-et-frais',
  CH: 'https://www.paybyphone.com/',
  DE: 'https://www.paybyphone.com/',
};
