import React from 'react';
import { Button, Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import ExtendedModal from 'components/Shared/Modal/index.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';

import styles from './style.module.scss';

const DeactivateSelfRegisteredModal = ({
  isModalVisible,
  setUserDeactivateSelection,
  setIsModalVisible,
}) => {
  const { translateText } = useTranslation();
  const { Title } = Typography;
  return (
    <ExtendedModal
      width={555}
      visible={isModalVisible}
      centered
      closable={false}
      footer={null}
      className={styles.deactivateSelfRegisteredModal}
      onCancel={() => {
        setUserDeactivateSelection(null);
        setIsModalVisible(false);
      }}
    >
      {
        <div className={styles.deactivateSelfRegisteredModalContent}>
          <Title level={2}>
            {translateText('corporateAccounts.subscription.selfRegisteredDeactivate.title')}
          </Title>
          <Title level={4} className={styles.deactivateSelfRegisteredModalSubTitle}>
            {translateText('corporateAccounts.subscription.selfRegisteredDeactivate.subTitle')}
          </Title>
          <Button
            block
            size="large"
            onClick={() => {
              setUserDeactivateSelection(
                AMPLITUDE_EVENT_TYPES.subscriptionDeactivationReasonDifferentApp,
              );
            }}
          >
            {translateText(
              'corporateAccounts.subscription.selfRegisteredDeactivate.differentParkingApp',
            )}
          </Button>
          <Button
            block
            size="large"
            onClick={() => {
              setUserDeactivateSelection(
                AMPLITUDE_EVENT_TYPES.subscriptiontDeactivationReasonTooExpensive,
              );
            }}
          >
            {translateText('corporateAccounts.subscription.selfRegisteredDeactivate.tooExpensive')}
          </Button>
          <Button
            block
            size="large"
            onClick={() => {
              setUserDeactivateSelection(
                AMPLITUDE_EVENT_TYPES.subscriptionAccountDeactivationReasonMissingFeatures,
              );
            }}
          >
            {translateText(
              'corporateAccounts.subscription.selfRegisteredDeactivate.missingFeatures',
            )}
          </Button>
          <Button
            block
            size="large"
            onClick={() => {
              setUserDeactivateSelection(
                AMPLITUDE_EVENT_TYPES.subscriptionAccountDeactivationReasonUnexpectedProduct,
              );
            }}
          >
            {translateText('corporateAccounts.subscription.selfRegisteredDeactivate.notExpected')}
          </Button>
          <Button
            block
            size="large"
            onClick={() => {
              setUserDeactivateSelection(
                AMPLITUDE_EVENT_TYPES.billingAccountDeactivationReasonOther,
              );
            }}
          >
            {translateText('corporateAccounts.subscription.selfRegisteredDeactivate.other')}
          </Button>
        </div>
      }
    </ExtendedModal>
  );
};

export default DeactivateSelfRegisteredModal;
