import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { getBrowserCountryBasedOnLanguage } from 'utils/browserLanguageCountryUtil';
import { COUNTRY_LINKS } from 'constants.js';
import useVerificationPage from './useVerificationPage';
import ResendVerificationLanding from './ResendVerificationLanding';
import ResendVerificationComponent from './ResendVerificationComponent';

import styles from './style.module.scss';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';

const VerificationPage = () => {
  const { onResendEmail, isResendEmailSuccessful } = useVerificationPage();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useRecaptcha();
  const { sendAmplitudeEvent } = useAmplitude();

  useEffect(() => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'showSignInButton',
        value: false,
      },
    });
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'showLanguageSelector',
        value: false,
      },
    });
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileView',
        value: false,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationUserVerificationPageViewed);
  }, [sendAmplitudeEvent]);

  const { translateText } = useTranslation();
  const locale = useSelector(state => state.settings.locale);

  const [isResendVerificationComponentOpen, setResendVerificationComponentOpen] = useState(false);

  const browserCountry = getBrowserCountryBasedOnLanguage(locale);

  const handleResendEmail = async () => {
    const recaptchaToken = await executeRecaptcha('submit');
    onResendEmail({ recaptchaToken });
  };

  useEffect(() => {
    if (isResendEmailSuccessful) {
      setResendVerificationComponentOpen(false);
    }
  }, [isResendEmailSuccessful]);

  return (
    <div className={styles.resendVerificationPage}>
      {isResendVerificationComponentOpen ? (
        <ResendVerificationComponent
          handleResendEmail={handleResendEmail}
          translateText={translateText}
        />
      ) : (
        <ResendVerificationLanding
          setResendVerificationComponentOpen={setResendVerificationComponentOpen}
          translateText={translateText}
        />
      )}

      <Divider style={{ margin: '32px' }} />
      <Typography>
        <p>{translateText('corporateAccounts.selfRegistration.success.supportMessage')}</p>{' '}
      </Typography>
      <div className={styles.contactSupport}>
        <QuestionCircleOutlined style={{ fontSize: '22px', color: '#66c24a' }} />
        <Typography>
          <p>
            <a
              href={COUNTRY_LINKS[browserCountry]?.contactSupport}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                sendAmplitudeEvent(
                  AMPLITUDE_EVENT_TYPES.selfRegistrationUserVerificationContactSupport,
                )
              }
              data-testid="contactSupportLink"
            >
              {translateText('corporateAccounts.selfRegistration.success.supportContact')}
            </a>
          </p>
        </Typography>
      </div>
    </div>
  );
};

export default VerificationPage;
