import http, { httpWrapper } from 'services/http';
import userService from 'services/user';
import {
  ENDPOINT_ADMINS,
  ENDPOINT_ADMINS_INVITE,
  ENDPOINT_USER_PROFILES,
  ENDPOINT_USERS,
  API_URL_CORE,
  API_URL_CORE_V2,
  ENDPOINT_VERIFY,
  ENDPOINT_REGISTER,
  ENDPOINT_SEND_VERIFICATION,
} from 'constants.js';
import { endpoint } from '.';

const create = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: endpoint(ENDPOINT_ADMINS),
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = http(requestOptions);
  return response;
};

const register = async data => {
  const { recaptchaToken, ...requestData } = data;
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_USERS, false, API_URL_CORE)}${ENDPOINT_REGISTER}`,
    headers: {
      'X-Pbp-VerificationToken': recaptchaToken,
      'X-Pbp-ClientType': 'businessapp',
      'x-pbp-recaptchatype': 'score',
    },
    data: requestData,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const registerV2 = async data => {
  const { recaptchaToken, ...requestData } = data;
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_USERS, false, API_URL_CORE_V2)}${ENDPOINT_REGISTER}`,
    headers: {
      'X-Pbp-VerificationToken': recaptchaToken,
      'X-Pbp-ClientType': 'businessapp',
      'x-pbp-recaptchatype': 'score',
    },
    data: requestData,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const verify = async data => {
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_USERS, false, API_URL_CORE)}${ENDPOINT_VERIFY}`,
    headers: {},
    data,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const resendEmail = async data => {
  const { recaptchaToken, ...requestData } = data;

  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_USERS, false, API_URL_CORE)}${ENDPOINT_SEND_VERIFICATION}`,
    headers: {
      'X-Pbp-VerificationToken': recaptchaToken,
      'X-Pbp-ClientType': 'businessapp',
      'x-pbp-recaptchatype': 'score',
    },
    data: requestData,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const read = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: endpoint(ENDPOINT_ADMINS),
    headers: {
      ...authHeaders,
    },
  };

  const response = http(requestOptions);
  return response;
};

const update = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'PUT',
    url: `${endpoint(ENDPOINT_ADMINS)}/${data.id}`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = http(requestOptions);
  return response;
};

const destroy = async id => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'DELETE',
    url: `${endpoint(ENDPOINT_ADMINS)}/${id}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = http(requestOptions);
  return response;
};

const invite = async adminIds => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: endpoint(ENDPOINT_ADMINS_INVITE),
    headers: {
      ...authHeaders,
    },
    data: adminIds,
  };

  const response = http(requestOptions);
  return response;
};

const profiles = async userId => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpoint(
      ENDPOINT_USER_PROFILES,
      false,
    )}/${userId}?corporateClientId=${userService.getCorporateClientId()}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = http(requestOptions);
  return response;
};

export default {
  read,
  create,
  register,
  registerV2,
  verify,
  resendEmail,
  update,
  destroy,
  invite,
  profiles,
};
