export const VEHICLE_EXPORT_CSV_HEADER_KEYS_EU = ['licensePlate', 'country', 'vin'];
export const VEHICLE_EXPORT_CSV_HEADER_KEYS = ['licensePlate', 'country', 'jurisdiction', 'vin'];

export default {
  VEHICLE_EXPORT_CSV_HEADER_KEYS_EU,
  VEHICLE_EXPORT_CSV_HEADER_KEYS,
};

export const VEHICLES_IMPORT_CSV_HEADER_KEYS = ['numberPlate', 'countryISO', 'vin'];

export const VEHICLES_IMPORT_CSV_HEADER_REGION_KEYS = [
  'licensePlate',
  'countryISO',
  'stateISO',
  'vin',
];

export const VEHICLE_ADD_ERRORS = {
  RestrictedCorporateClientTotalLimitExceeded: 'RestrictedCorporateClientTotalLimitExceeded',
  CorporateClientVehicleLimitExceeded: 'CorporateClientVehicleImportLimitExceeded',
};
